var braze_events = {
  // signup
  SIGNUP_ACCOUNT_TYPE_SELECTED: 'SIGNUP_ACCOUNT_TYPE_SELECTED',
  SIGNUP_PREFERRED_SERVICE_SELECTED: 'SIGNUP_PREFERRED_SERVICE_SELECTED',
  SIGNUP_INT_BILL_TYPE_SELECTED: 'SIGNUP_INT_BILL_TYPE_SELECTED',
  OPEN_BUSINESS_ACCOUNT: 'OPEN_BUSINESS_ACCOUNT',
  // send money
  SEND_MONEY_INITIATED: 'SEND_MONEY_INITIATED',
  SEND_MONEY_COUNTRY_SELECTED: 'SEND_MONEY_COUNTRY_SELECTED',
  SEND_MONEY_AMOUNT_CONFIRMED: 'SEND_MONEY_AMOUNT_CONFIRMED',
  SEND_MONEY_RECEIVING_METHOD_CONFIRMED: 'SEND_MONEY_RECEIVING_METHOD_CONFIRMED',
  SEND_MONEY_RECIPIENT_DETAILS_CONFIRMED: 'SEND_MONEY_RECIPIENT_DETAILS_CONFIRMED',
  SEND_MONEY_PERSONA_INITIATED: 'SEND_MONEY_PERSONA_INITIATED',
  SEND_MONEY_PERSONA_COMPLETED: 'SEND_MONEY_PERSONA_COMPLETED',
  SEND_MONEY_CUSTOMER_DETAILS_CONFIRMED: 'SEND_MONEY_CUSTOMER_DETAILS_CONFIRMED',
  SEND_MONEY_CUSTOMER_ADDRESS_CONFIRMED: 'SEND_MONEY_CUSTOMER_ADDRESS_CONFIRMED',
  SEND_MONEY_CUSTOMER_OCCUPATION_CONFIRMED: 'SEND_MONEY_CUSTOMER_OCCUPATION_CONFIRMED',
  SEND_MONEY_PURPOSE_CONFIRMED: 'SEND_MONEY_PURPOSE_CONFIRMED',
  SEND_MONEY_PREFERRED_BANK_SELECTED: 'SEND_MONEY_PREFERRED_BANK_SELECTED',
  SEND_MONEY_PAYMENT_METHOD_SELECTED: 'SEND_MONEY_PAYMENT_METHOD_SELECTED',
  SEND_MONEY_PAYMENT_DETAILS_CONFIRMED: 'SEND_MONEY_PAYMENT_DETAILS_CONFIRMED',
  FLINKS_IFRAME_AUTHENTICATION_STARTED: 'FLINKS_IFRAME_AUTHENTICATION_STARTED',
  FLINKS_IFRAME_AUTHENTICATION_ENDED: 'FLINKS_IFRAME_AUTHENTICATION_ENDED',
  SEND_MONEY_REWARDS_APPLIED: 'SEND_MONEY_REWARDS_APPLIED',
  SEND_MONEY_PROMOCODE_APPLIED: 'SEND_MONEY_PROMOCODE_APPLIED',
  // currency exchange
  CURRENCY_EXCHANGE_INITIATED: 'CURRENCY_EXCHANGE_INITIATED',
  CURRENCY_EXCHANGE_AMOUNT_CONFIRMED: 'CURRENCY_EXCHANGE_AMOUNT_CONFIRMED',
  CURRENCY_EXCHANGE_PURPOSE_CONFIRMED: 'CURRENCY_EXCHANGE_PURPOSE_CONFIRMED',
  CURRENCY_EXCHANGE_SELECT_ACCOUNT_CONNECTION_TYPE: 'CURRENCY_EXCHANGE_SELECT_ACCOUNT_CONNECTION_TYPE',
  CURRENCY_EXCHANGE_SELECT_PREFERRED_BANK: 'CURRENCY_EXCHANGE_SELECT_PREFERRED_BANK',
  CURRENCY_EXCHANGE_PERSONA_INITIATED: 'CURRENCY_EXCHANGE_PERSONA_INITIATED',
  CURRENCY_EXCHANGE_PERSONA_COMPLETED: 'CURRENCY_EXCHANGE_PERSONA_COMPLETED',
  CURRENCY_EXCHANGE_CUSTOMER_DETAILS_CONFIRMED: 'CURRENCY_EXCHANGE_CUSTOMER_DETAILS_CONFIRMED',
  CURRENCY_EXCHANGE_CUSTOMER_ADDRESS_CONFIRMED: 'CURRENCY_EXCHANGE_CUSTOMER_ADDRESS_CONFIRMED',
  CURRENCY_EXCHANGE_CUSTOMER_OCCUPATION_CONFIRMED: 'CURRENCY_EXCHANGE_CUSTOMER_OCCUPATION_CONFIRMED',
  CURRENCY_EXCHANGE_PAYMENT_BANK_ACCOUNT_SELECTED: 'CURRENCY_EXCHANGE_PAYMENT_BANK_ACCOUNT_SELECTED',
  CURRENCY_EXCHANGE_DEPOSIT_BANK_ACCOUNT_SELECTED: 'CURRENCY_EXCHANGE_DEPOSIT_BANK_ACCOUNT_SELECTED',
  CURRENCY_EXCHANGE_REWARDS_APPLIED: 'CURRENCY_EXCHANGE_REWARDS_APPLIED',
  CURRENCY_EXCHANGE_PROMOCODE_APPLIED: 'CURRENCY_EXCHANGE_PROMOCODE_APPLIED',
  PROMO_CODE_APPLIED: 'PROMO_CODE_APPLIED',
  // add new recipient
  ADD_NEW_RECIPIENT_STARTED: 'ADD_NEW_RECIPIENT_STARTED',
  ADD_NEW_RECIPIENT_COUNTRY_SELECTED: 'ADD_NEW_RECIPIENT_COUNTRY_SELECTED',
  ADD_NEW_RECIPIENT_RECEIVING_METHOD_SELECTED: 'ADD_NEW_RECIPIENT_RECEIVING_METHOD_SELECTED',
  ADD_NEW_RECIPIENT_PERSONAL_DETAILS_ENTERED: 'ADD_NEW_RECIPIENT_PERSONAL_DETAILS_ENTERED',
  ADD_NEW_RECIPIENT_BANK_DETAILS_ENTERED: 'ADD_NEW_RECIPIENT_BANK_DETAILS_ENTERED',
  // balance deposit
  BALANCE_DEPOSIT_INITIATED: 'BALANCE_DEPOSIT_INITIATED',
  BALANCE_DEPOSIT_AMOUNT_CONFIRMED: 'BALANCE_DEPOSIT_AMOUNT_CONFIRMED',
  BALANCE_DEPOSIT_PAYMENT_METHOD_SELECTED: 'BALANCE_DEPOSIT_PAYMENT_METHOD_SELECTED',
  BALANCE_DEPOSIT_PAYMENT_DETAILS_CONFIRMED: 'BALANCE_DEPOSIT_PAYMENT_DETAILS_CONFIRMED',
  // balance withdrawal
  BALANCE_WITHDRAWAL_INITIATED: 'BALANCE_WITHDRAWAL_INITIATED',
  BALANCE_WITHDRAWAL_AMOUNT_CONFIRMED: 'BALANCE_WITHDRAWAL_AMOUNT_CONFIRMED',
  BALANCE_WITHDRAWAL_METHOD_SELECTED: 'BALANCE_WITHDRAWAL_METHOD_SELECTED',
  BALANCE_WITHDRAWAL_DETAILS_CONFIRMED: 'BALANCE_WITHDRAWAL_DETAILS_CONFIRMED',
  // Level 1 verification
  LEVEL1_VERIFICATION_STARTED: 'LEVEL1_VERIFICATION_STARTED',
  LEVEL1_PERSONA_INITIATED: 'LEVEL1_PERSONA_INITIATED',
  LEVEL1_PERSONA_COMPLETED: 'LEVEL1_PERSONA_COMPLETED',
  LEVEL1_VERIFICATION_CUSTOMER_DETAILS_CONFIRMED: 'LEVEL1_VERIFICATION_CUSTOMER_DETAILS_CONFIRMED',
  LEVEL1_VERIFICATION_CUSTOMER_ADDRESS_CONFIRMED: 'LEVEL1_VERIFICATION_CUSTOMER_ADDRESS_CONFIRMED',
  LEVEL1_VERIFICATION_CUSTOMER_OCCUPATION_CONFIRMED: 'LEVEL1_VERIFICATION_CUSTOMER_OCCUPATION_CONFIRMED',
  LEVEL1_VERIFICATION_COMPLETED: 'LEVEL1_VERIFICATION_COMPLETED',
  // Level 2 verification
  LEVEL2_VERIFICATION_STARTED: 'LEVEL2_VERIFICATION_STARTED',
  LEVEL2_VERIFICATION_DOCUMENT_SELECTED: 'LEVEL2_VERIFICATION_DOCUMENT_SELECTED',
  LEVEL2_DOCUMENT_UPLOADED: 'LEVEL2_DOCUMENT_UPLOADED',
  LEVEL2_DOCUMENT_SUBMITTED: 'LEVEL2_DOCUMENT_SUBMITTED',
  // level 3 verification
  LEVEL3_VERIFICATION_INITIATED: 'LEVEL3_VERIFICATION_INITIATED',
  LEVEL3_VERIFICATION_VIDEO_STARTED: 'LEVEL3_VERIFICATION_VIDEO_STARTED',
  LEVEL3_VERIFICATION_VIDEO_RECORDED: 'LEVEL3_VERIFICATION_VIDEO_RECORDED',
  LEVEL3_VERIFICATION_VIDEO_UPLOADED: 'LEVEL3_VERIFICATION_VIDEO_UPLOADED',
  LEVEL3_VERIFICATION_VIDEO_SUBMITTED: 'LEVEL3_VERIFICATION_VIDEO_SUBMITTED',
  // gift card
  GIFT_CARD_INITIATED: 'GIFT_CARD_INITIATED',
  GIFT_CARD_VALUE_SELECTED: 'GIFT_CARD_VALUE_SELECTED',
  GIFT_CARD_COUNTRY_SELECTED: 'GIFT_CARD_COUNTRY_SELECTED',
  GIFT_CARD_PROVIDER_SELECTED: 'GIFT_CARD_PROVIDER_SELECTED',
  GIFT_CARD_PAYMENT_SELECTED: 'GIFT_CARD_PAYMENT_SELECTED',
  GIFT_CARD_PAYMENT_DETAILS_ENTERED: 'GIFT_CARD_PAYMENT_DETAILS_ENTERED',
  GIFT_CARD_OVERVIEW_CONFIRMED: 'GIFT_CARD_OVERVIEW_CONFIRMED',
  // mobile topup
  MOBILE_TOP_UP_INITIATED: 'MOBILE_TOP_UP_INITIATED',
  MOBILE_TOP_UP_COUNTRY_SELECTED: 'MOBILE_TOP_UP_COUNTRY_SELECTED',
  MOBILE_TOP_UP_PHONE_NUMBER_SUBMITTED: 'MOBILE_TOP_UP_PHONE_NUMBER_SUBMITTED',
  MOBILE_TOP_UP_VALUE_SELECTED: 'MOBILE_TOP_UP_VALUE_SELECTED',
  MOBILE_TOP_UP_PAYMENT_METHOD_SELECTED: 'MOBILE_TOP_UP_PAYMENT_METHOD_SELECTED',
  MOBILE_TOP_UP_PAYMENT_DETAILS_ENTERED: 'MOBILE_TOP_UP_PAYMENT_DETAILS_ENTERED',
  MOBILE_TOP_UP_OVERVIEW_CONFIRMED: 'MOBILE_TOP_UP_OVERVIEW_CONFIRMED',
  // bill payment 
  BILL_PAYMENT_INITIATED: 'BILL_PAYMENT_INITIATED',
  BILL_PAYMENT_COUNTRY_SELECTED: 'BILL_PAYMENT_COUNTRY_SELECTED',
  BILL_PAYMENT_BILL_TYPE_SELECTED: 'BILL_PAYMENT_BILL_TYPE_SELECTED',
  BILL_PAYMENT_PROVIDER_AND_ACCOUNT_NUMBER_SELECTED: 'BILL_PAYMENT_PROVIDER_AND_ACCOUNT_NUMBER_SELECTED',
  BILL_PAYMENT_VALUE_SELECTED: 'BILL_PAYMENT_VALUE_SELECTED',
  BILL_PAYMENT_PAYMENT_SELECTED: 'BILL_PAYMENT_PAYMENT_SELECTED',
  BILL_PAYMENT_PAYMENT_DETAILS_ENTERED: 'BILL_PAYMENT_PAYMENT_DETAILS_ENTERED',
  BILL_PAYMENT_OVERVIEW_CONFIRMED: 'BILL_PAYMENT_OVERVIEW_CONFIRMED',
  // Business Verification
  BA_VERIFICATION_STARTED: 'BA_VERIFICATION_STARTED',
  BA_VERIFICATION_BUSINESS_ADDRESS_DOCUMENT_SELECTED: 'BA_VERIFICATION_BUSINESS_ADDRESS_DOCUMENT_SELECTED',
  BA_VERIFICATION_BUSINESS_ADDRESS_DOCUMENT_UPLOADED: 'BA_VERIFICATION_BUSINESS_ADDRESS_DOCUMENT_UPLOADED',
  BA_VERIFICATION_BUSINESS_REGISTRATION_DOCUMENT_UPLOADED: 'BA_VERIFICATION_BUSINESS_REGISTRATION_DOCUMENT_UPLOADED',
  BA_VERIFICATION_BUSINESS_REGISTRATION_DOCUMENT_SUBMITTED: 'BA_VERIFICATION_BUSINESS_REGISTRATION_DOCUMENT_SUBMITTED',
  BA_VERIFICATION_OWNERSHIP_SELECTED: 'BA_VERIFICATION_OWNERSHIP_SELECTED',
  BA_VERIFICATION_ADDITIONAL_OWNER_PERSONAL_INFORMATION_SUBMITTED: 'BA_VERIFICATION_ADDITIONAL_OWNER_PERSONAL_INFORMATION_SUBMITTED',
  BA_VERIFICATION_ADDITIONAL_OWNER_ADDRESS_INFORMATION_SUBMITTED: 'BA_VERIFICATION_ADDITIONAL_OWNER_ADDRESS_INFORMATION_SUBMITTED',
  BA_VERIFICATION_ADDITIONAL_OWNERS_ADDED: 'BA_VERIFICATION_ADDITIONAL_OWNERS_ADDED',
  BA_VERIFICATION_DIRECTORS_SELECTED: 'BA_VERIFICATION_DIRECTORS_SELECTED',
  BA_VERIFICATION_ADDITIONAL_DIRECTOR_PERSONAL_INFORMATION_SUBMITTED: 'BA_VERIFICATION_ADDITIONAL_DIRECTOR_PERSONAL_INFORMATION_SUBMITTED',
  BA_VERIFICATION_ADDITIONAL_DIRECTOR_ADDRESS_INFORMATION_SUBMITTED: 'BA_VERIFICATION_ADDITIONAL_DIRECTOR_ADDRESS_INFORMATION_SUBMITTED',
  BA_VERIFICATION_ADDITIONAL_DIRECTOR_ID_DOCUMENT_SELECTED: 'BA_VERIFICATION_ADDITIONAL_DIRECTOR_ID_DOCUMENT_SELECTED',
  BA_VERIFICATION_ADDITIONAL_DIRECTOR_ID_DOCUMENT_UPLOADED: 'BA_VERIFICATION_ADDITIONAL_DIRECTOR_ID_DOCUMENT_UPLOADED',
  BA_VERIFICATION_ADDITIONAL_DIRECTOR_ID_DOCUMENT_SUBMITTED: 'BA_VERIFICATION_ADDITIONAL_DIRECTOR_ID_DOCUMENT_SUBMITTED',
  BA_VERIFICATION_ADDITIONAL_DIRECTORS_ADDED: 'BA_VERIFICATION_ADDITIONAL_DIRECTORS_ADDED',
  BA_PERSONA_INITIATED: 'BA_PERSONA_INITIATED',
  BA_PERSONA_COMPLETED: 'BA_PERSONA_COMPLETED',
  BA_VERIFICATION_CUSTOMER_DETAILS_CONFIRMED: 'BA_VERIFICATION_CUSTOMER_DETAILS_CONFIRMED',
  BA_VERIFICATION_CUSTOMER_ADDRESS_CONFIRMED: 'BA_VERIFICATION_CUSTOMER_ADDRESS_CONFIRMED',
  BA_VERIFICATION_CUSTOMER_OCCUPATION_CONFIRMED: 'BA_VERIFICATION_CUSTOMER_OCCUPATION_CONFIRMED',
  // esim 
  ESIM_INITIATED: 'ESIM_INITIATED',
  ESIM_COMPATIBILITY_CONFIRMED: 'ESIM_COMPATIBILITY_CONFIRMED',
  ESIM_DESTINATION_SELECTED: 'ESIM_DESTINATION_SELECTED',
  ESIM_PLAN_SELECTED: 'ESIM_PLAN_SELECTED',
  ESIM_RECEIVING_EMAIL_SUBMITTED: 'ESIM_RECEIVING_EMAIL_SUBMITTED',
  ESIM_PAYMENT_METHOD_SELECTED: 'ESIM_PAYMENT_METHOD_SELECTED',
  ESIM_PAYMENT_DETAILS_ENTERED: 'ESIM_PAYMENT_DETAILS_ENTERED',
  ESIM_PAYMENT_OVERVIEW_CONFIRMED: 'ESIM_PAYMENT_OVERVIEW_CONFIRMED'
};
export default braze_events;