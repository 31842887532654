export var SET_CE_SENDING_CURRENCY_INFO = 'SET_CE_SENDING_CURRENCY_INFO';
export var SET_CE_RECEIVING_CURRENCY_INFO = 'SET_CE_RECEIVING_CURRENCY_INFO';
export var SET_CE_SENDING_CURRENCY_RATE_DETAILS = 'SET_CE_SENDING_CURRENCY_RATE_DETAILS';
export var SET_CE_RECEIVING_CURRENCY_RATE_DETAILS = 'SET_CE_RECEIVING_CURRENCY_RATE_DETAILS';
export var SET_CE_SENDING_AMOUNT = 'SET_CE_SENDING_AMOUNT';
export var SET_CE_RECEIVING_AMOUNT = 'SET_CE_RECEIVING_AMOUNT';
export var SET_CE_COMPLIANCE = 'SET_CE_COMPLIANCE';
export var CLEAR_CE_STATE = 'CLEAR_CE_STATE';
export var SET_CE_TRANSFER_PURPOSE = 'SET_CE_TRANSFER_PURPOSE';
export var SET_CE_CUS_EFT_LIST = 'SET_CE_CUS_EFT_LIST';
export var SET_CE_WALLET_BALANCE = 'SET_CE_WALLET_BALANCE';
export var SET_CE_TRANSFER_AMOUNT = 'SET_CE_TRANSFER_AMOUNT';
export var SET_CE_PAYMENT_METHOD = 'SET_CE_PAYMENT_METHOD';
export var SET_CE_PAYMENT_EFT_INFO = 'SET_CE_PAYMENT_EFT_INFO';
export var SET_CE_PAYMENT_CONNECTION_TYPE = 'SET_CE_PAYMENT_CONNECTION_TYPE';
export var SET_CE_PAYMENT_BANKS = 'SET_CE_PAYMENT_BANKS';
export var SET_CE_PAYMENT_FLINKS_INFO = 'SET_CE_PAYMENT_FLINKS_INFO';
export var CLEAR_CE_PAYMENT_FLINKS_INFO = 'CLEAR_CE_PAYMENT_FLINKS_INFO';
export var SET_CE_DEPOSIT_EFT_INFO = 'SET_CE_DEPOSIT_EFT_INFO';
export var SET_CE_DEPOSIT_CONNECTION_TYPE = 'SET_CE_DEPOSIT_CONNECTION_TYPE';
export var SET_CE_DEPOSIT_METHOD = 'SET_CE_DEPOSIT_METHOD';
export var SET_CE_DEPOSIT_CUS_EFT_LIST = 'SET_CE_DEPOSIT_CUS_EFT_LIST';
export var SET_CE_DEPOSIT_FLINKS_INFO = 'SET_CE_DEPOSIT_FLINKS_INFO';
export var CLEAR_CE_DEPOSIT_FLINKS_INFO = 'CLEAR_CE_DEPOSIT_FLINKS_INFO';
export var SET_CE_RATE_DETAILS = 'SET_CE_RATE_DETAILS';
export var SET_CE_NEW_MANUAL_CONNECTION = 'SET_CE_NEW_MANUAL_CONNECTION';
export var SET_CE_DEPOSIT_DATE = 'SET_CE_DEPOSIT_DATE';
export var SET_CE_ADDITIONAL_INFO = 'SET_CE_ADDITIONAL_INFO';