import _defineProperty from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SET_COUNTRY_GIFT, SET_RECIPIENT_EMAIL, SET_OPERATOR_GIFT, SET_PRODUCT_DETAIL_GIFT, SET_LIST_OF_PRODUCTS_GIFT, SET_REQ_FIELDS_GIFT, SET_REQ_FIELD_INFO_GIFT, SET_CUSTOM_AMNT_GIFT, SET_CUSTOM_MSG_GIFT, CLEAR_PRODUCTS_INFO_GIFT, CLEAR_OPERATOR_INFO_GIFT, CLEAR_ENTIRE_STATE_GIFT, SET_DEFAULT_COUNTRY } from './actionTypes';
var initialState = {
  serviceId: 2,
  countryDetails: null,
  recipientEmail: null,
  operator: null,
  selectedProduct: null,
  products: null,
  requiredFields: [],
  reqFieldInfo: null,
  customAmount: null,
  customMessage: null,
  defaultCountry: null
};
var reducer = function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_COUNTRY_GIFT:
      return _objectSpread(_objectSpread({}, state), {}, {
        countryDetails: action.payload
      });
    case SET_RECIPIENT_EMAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        recipientEmail: action.payload
      });
    case SET_OPERATOR_GIFT:
      return _objectSpread(_objectSpread({}, state), {}, {
        operator: action.payload
      });
    case SET_PRODUCT_DETAIL_GIFT:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedProduct: action.payload
      });
    case SET_LIST_OF_PRODUCTS_GIFT:
      return _objectSpread(_objectSpread({}, state), {}, {
        products: action.payload
      });
    case SET_REQ_FIELDS_GIFT:
      return _objectSpread(_objectSpread({}, state), {}, {
        requiredFields: action.payload
      });
    case SET_REQ_FIELD_INFO_GIFT:
      return _objectSpread(_objectSpread({}, state), {}, {
        reqFieldInfo: action.payload
      });
    case SET_CUSTOM_AMNT_GIFT:
      return _objectSpread(_objectSpread({}, state), {}, {
        customAmount: action.payload
      });
    case SET_CUSTOM_MSG_GIFT:
      return _objectSpread(_objectSpread({}, state), {}, {
        customMessage: action.payload
      });
    case SET_DEFAULT_COUNTRY:
      return _objectSpread(_objectSpread({}, state), {}, {
        defaultCountry: action.payload
      });
    case CLEAR_OPERATOR_INFO_GIFT:
      return _objectSpread(_objectSpread({}, state), {}, {
        operator: null
      });
    case CLEAR_PRODUCTS_INFO_GIFT:
      return _objectSpread(_objectSpread({}, state), {}, {
        products: null,
        requiredFields: [],
        selectedProduct: null,
        reqFieldInfo: null,
        customAmount: null,
        customMessage: null
      });
    case CLEAR_ENTIRE_STATE_GIFT:
      return _objectSpread({}, initialState);
    default:
      return state;
  }
};
export default reducer;