export var SET_TRANSACTION_UNIQUE_ID = 'SET_TRANSACTION_UNIQUE_ID';
export var SET_TRANSFER_PURPOSE = 'SET_TRANSFER_PURPOSE';
export var SET_TRANSFER_AMOUNT = 'SET_TRANSFER_AMOUNT';
export var SET_RECEIVING_AMOUNT = 'SET_RECEIVING_AMOUNT';
export var SET_TRANSFER_RATE = 'SET_TRANSFER_RATE';
export var SET_TRANSFER_RATE_SPECIAL = 'SET_TRANSFER_RATE_SPECIAL';
export var SET_TRANSFER_COMMISSION = 'SET_TRANSFER_COMMISSION';
export var SET_DESTINATION_COUNTRY_CURRENCY_CODE = 'SET_DESTINATION_COUNTRY_CURRENCY_CODE';
export var SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE';
export var SET_SETTLEMENT_TYPE = 'SET_SETTLEMENT_TYPE';
export var SET_FUNDING_TIME = 'SET_FUNDING_TIME';
export var SET_SPECIAL_RATE_TYPE = 'SET_SPECIAL_RATE_TYPE';
export var SET_CARD_INFO = 'SET_CARD_INFO';
export var SET_EFT_INFO = 'SET_EFT_INFO';
export var SET_TRANSACTION_PROCESSING_INFO = 'SET_TRANSACTION_PROCESSING_INFO';
export var SET_REWARDS_AMOUNT = 'SET_REWARDS_AMOUNT';
export var SET_ADD_RECIPIENT_FLOW = 'SET_ADD_RECIPIENT_FLOW';
export var SET_WALLET_BALANCE_APPLIED = 'SET_WALLET_BALANCE_APPLIED';
export var CLEAR_TRAN_STATE = 'CLEAR_TRAN_STATE';
export var CLEAR_PAYMENT_OPTION = 'CLEAR_PAYMENT_OPTION';
export var CLEAR_STATE = 'CLEAR_STATE';
export var SET_INSTANT_SPEED = 'SET_INSTANT_SPEED';
export var SET_TRANSFER_RATE_ID = 'SET_TRANSFER_RATE_ID';
export var SET_FLINKS_LOGS_ID = 'SET_FLINKS_LOGS_ID';
export var SET_FLINKS_LOGIN_ID = 'SET_FLINKS_LOGIN_ID';
export var SET_MONERIS_CHALLANGE_PARAMS = 'SET_MONERIS_CHALLANGE_PARAMS';
export var SET_TRACK_TRANSACTION = 'SET_TRACK_TRANSACTION';
export var SET_LOADING = 'SET_LOADING';
export var SET_ERROR = 'SET_ERROR';
export var SET_TRACK_TOKEN = 'SET_TRACK_TOKEN';
export var SET_TRACK_URL = 'SET_TRACK_URL';
export var SET_CARD_TYPE = 'SET_CARD_TYPE';
export var SET_CUSTOM_FILTER = 'SET_CUSTOM_FILTER';