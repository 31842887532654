import _defineProperty from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SET_GAME_EXISTS, SET_GAMES, SET_GAME_STEPS, SET_LOADING, SET_ERROR } from './actionTypes';
var initialState = {
  loading: false,
  doGameExists: false,
  games: [],
  gameSteps: [],
  selectedGame: null,
  selectedStep: null,
  answers: []
};
var reducer = function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_GAME_EXISTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        doGameExists: action.payload
      });
    case SET_GAMES:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        games: action.payload
      });
    case SET_GAME_STEPS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        gameSteps: action.payload
      });
    case SET_LOADING:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: action.payload
      });
    case SET_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        errorMessage: action.payload
      });
    default:
      return state;
  }
};
export default reducer;