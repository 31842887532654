export var SET_BEN_COUNTRY_ID = 'SET_BEN_COUNTRY_ID';
export var SET_BEN_CURRENCY = 'SET_BEN_CURRENCY';
export var SET_RECEIVING_METHOD = 'SET_PAYMENT_METHOD';
export var SET_BEN_PAYMENT_METHOD = 'SET_BEN_PAYMENT_METHOD';
export var SET_BEN_PAYMENT_METHOD_OPTION = 'SET_BEN_PAYMENT_METHOD_OPTION';
export var SET_BENEFICIARY_GROUP = 'SET_BENEFICIARY_GROUP';
export var SET_BEN_PAYMENT_OPTION_GROUP = 'SET_BEN_PAYMENT_OPTION_GROUP';
export var SET_BEN_PAYMENT_METHOD_DETAILS = 'SET_BEN_PAYMENT_METHOD_DETAILS';
export var CLEAR_BEN_STATE = 'CLEAR_BEN_STATE';
export var CLEAR_RECEIVING_METHOD = 'CLEAR_RECEIVING_METHOD';
export var SET_OPEN_REQUEST_DIALOG = 'SET_OPEN_REQUEST_DIALOG';
export var FETCH_BENEFICIARY = 'FETCH_BENEFICIARY';
export var UPDATE_HAS_ONLY_PRIORITY = 'UPDATE_HAS_ONLY_PRIORITY';
export var UPDATE_BENEFICIARY_PAYMENT_INFOS_LIST = 'UPDATE_BENEFICIARY_PAYMENT_INFOS_LIST';
export var ADD_ADDITIONAL_FIELDS_CHILD = 'ADD_ADDITIONAL_FIELDS_CHILD';
export var SET_RECIPIENT_STATUS = 'SET_RECIPIENT_STATUS';
export var SET_ADD_NEW_RECIPIENT_FORM = 'SET_ADD_NEW_RECIPIENT_FORM';
export var SET_RECIPIENT_SUCCESS_DIALOG = 'SET_RECIPIENT_SUCCESS_DIALOG';
export var SET_RECIPIENT_CURRENT_FLOW = 'SET_RECIPIENT_CURRENT_FLOW';
export var SET_RECIPIENT_DEL_SUCCESS_DIALOG = 'SET_RECIPIENT_DEL_SUCCESS_DIALOG';