import { SET_FLINKS_INFO, CLEAN_FLINKS_INFO, STATUS_INTEGRATION_STATUS, SET_REDEPOSIT, UNSET_REDEPOSIT } from "./actionTypes";
import customerActionTypes from '../customer/actionTypes';
import { updateObject } from '../../shared/utility';
var initialState = {
  loginId: null,
  institution: null,
  url: null,
  flinksIntegrationStatus: null,
  redepositStatus: {
    dialog: false,
    title: '',
    message: ''
  }
};
var setFlinksInfo = function setFlinksInfo(state, _ref) {
  var flinksInfo = _ref.flinksInfo;
  return updateObject(state, flinksInfo);
};
var setStatusIntegrationStatus = function setStatusIntegrationStatus(state, _ref2) {
  var flinksIntegrationStatus = _ref2.flinksIntegrationStatus;
  return updateObject(state, {
    flinksIntegrationStatus: flinksIntegrationStatus
  });
};
var clearFlinksInfo = function clearFlinksInfo(state) {
  return updateObject(state, {
    loginId: null,
    institution: null,
    url: null,
    flinksIntegrationStatus: null,
    redepositDialog: false
  });
};
var setRedepositStatus = function setRedepositStatus(state) {
  return updateObject(state, {
    redepositDialog: {
      dialog: true,
      title: "You're almost there!",
      message: "\n    We sent two micro-deposits to your bank account in order to verify it. They should appear within 1-3 business days. Once you receive these deposits, go to your Payment Preference Settings and enter those amounts to complete verification. \n    "
    }
  });
};
var unsetRedepositStatus = function unsetRedepositStatus(state) {
  return updateObject(state, {
    redepositDialog: {
      dialog: false,
      message: ''
    }
  });
};
var flinksReducers = function flinksReducers() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_FLINKS_INFO:
      return setFlinksInfo(state, action.payload);
    case CLEAN_FLINKS_INFO:
      return clearFlinksInfo(state);
    case customerActionTypes.CLEAR_STATE:
      return clearFlinksInfo(state);
    case STATUS_INTEGRATION_STATUS:
      return setStatusIntegrationStatus(state, action.payload);
    case SET_REDEPOSIT:
      return setRedepositStatus(state);
    case UNSET_REDEPOSIT:
      return unsetRedepositStatus(state);
    default:
      return state;
  }
};
export default flinksReducers;