import _defineProperty from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import transactionProcessingStatuses from '../../shared/const/transactionProcessingStatuses';
import { SET_TRANSFER_PURPOSE, SET_TRANSFER_AMOUNT, SET_RECEIVING_AMOUNT, SET_PAYMENT_TYPE, SET_SETTLEMENT_TYPE, SET_FUNDING_TIME, SET_SPECIAL_RATE_TYPE, SET_TRANSFER_RATE, SET_TRANSFER_RATE_SPECIAL, SET_TRANSACTION_UNIQUE_ID, SET_TRANSACTION_PROCESSING_INFO, SET_DESTINATION_COUNTRY_CURRENCY_CODE, SET_TRANSFER_COMMISSION, CLEAR_TRAN_STATE, CLEAR_STATE, CLEAR_PAYMENT_OPTION, SET_CARD_INFO, SET_REWARDS_AMOUNT, SET_ADD_RECIPIENT_FLOW, SET_WALLET_BALANCE_APPLIED, SET_EFT_INFO, SET_INSTANT_SPEED, SET_TRANSFER_RATE_ID, SET_FLINKS_LOGS_ID, SET_FLINKS_LOGIN_ID, SET_TRACK_TRANSACTION, SET_LOADING, SET_ERROR, SET_TRACK_TOKEN, SET_TRACK_URL, SET_MONERIS_CHALLANGE_PARAMS, SET_CARD_TYPE, SET_CUSTOM_FILTER } from './actionTypes';
import { updateObject } from '../../shared/utility';
var initialState = {
  transaction_unique_id: '',
  transaction_purpose: '',
  transfer_amount: '',
  final_transfer_rate: '',
  special_rate: null,
  special_rate_transfer_amount_limit: null,
  final_transfer_commission: '',
  receiving_amount: '',
  payment_type: '',
  settlement_time: '',
  funding_time: '',
  special_rate_type: '',
  destination_country_currency_code: '',
  rewards_amount: '',
  wallet_balance_applied: null,
  card: {},
  eft: null,
  add_recipient_flow: false,
  is_instant_speed: false,
  rate_id: null,
  transaction_processing_info: {
    status: transactionProcessingStatuses.NOT_INITIATED,
    success_dialog_open: false,
    failed_dialog_open: false,
    exchange_success_dialog_open: false,
    required_level_to_upgrade: null,
    debit_card_error: null
  },
  loading: false,
  errorMessage: null,
  trackToken: null,
  trackUrl: null,
  track: {},
  transactionCustomFilters: null
};
var setInstantSpeed = function setInstantSpeed(state, _ref) {
  var is_instant_speed = _ref.is_instant_speed;
  return updateObject(state, {
    is_instant_speed: is_instant_speed
  });
};
var setTransferRateId = function setTransferRateId(state, _ref2) {
  var rate_id = _ref2.rate_id;
  return updateObject(state, {
    rate_id: rate_id
  });
};
var transactionReducers = function transactionReducers() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_TRANSACTION_UNIQUE_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        transaction_unique_id: action.payload
      });
    case SET_TRANSFER_PURPOSE:
      return _objectSpread(_objectSpread({}, state), {}, {
        transaction_purpose: action.payload
      });
    case SET_TRANSFER_AMOUNT:
      return _objectSpread(_objectSpread({}, state), {}, {
        transfer_amount: action.payload
      });
    case SET_PAYMENT_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, {
        payment_type: action.payload
      });
    case SET_SETTLEMENT_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, {
        settlement_time: action.payload
      });
    case SET_FUNDING_TIME:
      return _objectSpread(_objectSpread({}, state), {}, {
        funding_time: action.payload
      });
    case SET_SPECIAL_RATE_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, {
        special_rate_type: action.payload
      });
    case SET_TRANSFER_RATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        final_transfer_rate: action.payload
      });
    case SET_TRANSFER_RATE_SPECIAL:
      return _objectSpread(_objectSpread({}, state), action.payload);
    case SET_RECEIVING_AMOUNT:
      return _objectSpread(_objectSpread({}, state), {}, {
        receiving_amount: action.payload
      });
    case SET_TRANSFER_COMMISSION:
      return _objectSpread(_objectSpread({}, state), {}, {
        final_transfer_commission: action.payload || '0'
      });
    case SET_DESTINATION_COUNTRY_CURRENCY_CODE:
      return _objectSpread(_objectSpread({}, state), {}, {
        destination_country_currency_code: action.payload
      });
    case SET_CARD_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        card: action.payload
      });
    case SET_EFT_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        eft: action.payload
      });
    case SET_REWARDS_AMOUNT:
      return _objectSpread(_objectSpread({}, state), {}, {
        rewards_amount: action.payload
      });
    case SET_ADD_RECIPIENT_FLOW:
      return _objectSpread(_objectSpread({}, state), {}, {
        add_recipient_flow: action.payload
      });
    case SET_TRANSACTION_PROCESSING_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        transaction_processing_info: _objectSpread(_objectSpread({}, state.transaction_processing_info), action.payload)
      });
    case SET_WALLET_BALANCE_APPLIED:
      return _objectSpread(_objectSpread({}, state), {}, {
        wallet_balance_applied: action.payload
      });
    case CLEAR_TRAN_STATE:
    case CLEAR_STATE:
      return initialState;
    case CLEAR_PAYMENT_OPTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        final_transfer_commission: '',
        payment_type: '',
        settlement_time: ''
      });
    case SET_INSTANT_SPEED:
      return setInstantSpeed(state, action.payload);
    case SET_TRANSFER_RATE_ID:
      return setTransferRateId(state, action.payload);
    case SET_FLINKS_LOGS_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        flinks_logs_id: action.payload
      });
    case SET_FLINKS_LOGIN_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        flinks_login_id: action.payload
      });
    case SET_MONERIS_CHALLANGE_PARAMS:
      return _objectSpread(_objectSpread({}, state), {}, {
        challenge_params: action.payload
      });
    case SET_TRACK_TRANSACTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        track: action.payload,
        loading: false
      });
    case SET_LOADING:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: action.payload
      });
    case SET_TRACK_TOKEN:
      return _objectSpread(_objectSpread({}, state), {}, {
        trackToken: action.payload
      });
    case SET_TRACK_URL:
      return _objectSpread(_objectSpread({}, state), {}, {
        trackUrl: action.payload,
        loading: false
      });
    case SET_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        errorMessage: action.payload,
        loading: false
      });
    case SET_CARD_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, {
        cardType: action.payload
      });
    case SET_CUSTOM_FILTER:
      return _objectSpread(_objectSpread({}, state), {}, {
        transactionCustomFilters: action.payload
      });
    default:
      return state;
  }
};
export default transactionReducers;