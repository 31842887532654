import _defineProperty from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import actionTypes from './actionTypes';
var initState = {
  compliance: {},
  cus_unique_id: null,
  is_braze_enabled: false,
  promo_redirect: '/send-money'
};
var customerReducers = function customerReducers() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), action.payload.personalInformation);
    case actionTypes.SIGN_UP_SUCCESS:
      return _objectSpread(_objectSpread({}, state), action.payload.personalInformation);
    case actionTypes.CUSTOMER_UPDATED:
      return _objectSpread(_objectSpread({}, state), action.payload);
    case actionTypes.CUSTOMER_PHONE_UPDATED:
      return _objectSpread(_objectSpread({}, state), {}, {
        cus_phone1: action.payload
      });
    case actionTypes.CUSTOMER_EMPLOYMENT_UPDATED:
      return _objectSpread(_objectSpread({}, state), action.payload);
    case actionTypes.SET_COMPLIANCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        compliance: action.payload
      });
    case actionTypes.PROMO_REDIRECT:
      return _objectSpread(_objectSpread({}, state), {}, {
        promo_redirect: action.payload
      });
    case actionTypes.IS_BRAZE_ENABLED:
      return _objectSpread(_objectSpread({}, state), {}, {
        is_braze_enabled: action.payload
      });
    case actionTypes.SET_BRAZE_CONTENT_CARDS:
      return _objectSpread(_objectSpread({}, state), {}, {
        braze_content_cards: action.payload
      });
    case actionTypes.SET_CUSTOMER_UNIQ_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        cus_unique_id: action.payload
      });
    case actionTypes.CLEAR_STATE:
      return _objectSpread({}, initState);
    default:
      return state;
  }
};
export default customerReducers;