export var COMPLIANCE = 'COMPLIANCE';
// Indicates user is adding a beneficiary (first transfer or not)
export var ADD_NEW_RECIPIENT = 'ADD_NEW_RECIPIENT';
export var ADD_NEW_RECIPIENT_FORM = 'ADD_NEW_RECIPIENT_FORM';
// Indicates the user has no beneficiaries and transaction should start with add beneficiary
export var FIRST_TRANSFER = 'FIRST_TRANSFER';
export var SET_MT_RECIPIENT = 'SET_MT_RECIPIENT';
export var SET_MT_RECEIVING_METHOD = 'SET_MT_RECEIVING_METHOD';
export var CLEAR_MT_STATE = 'CLEAR_MT_STATE';
export var SET_MT_IS_INSTANT_SPEED = 'SET_MT_IS_INSTANT_SPEED';
export var SET_MT_SENDING_AMOUNT = 'SET_MT_SENDING_AMOUNT';
export var SET_MT_RECEIVING_AMOUNT = 'SET_MT_RECEIVING_AMOUNT';
export var SET_MT_RATE_DETAILS = 'SET_MT_RATE_DETAILS';
export var SET_MT_PURPOSE_SELECTED = 'SET_MT_PURPOSE_SELECTED';
export var SET_MT_AVAIL_PAY_METHODS = 'SET_MT_AVAIL_PAY_METHODS';
export var SET_MT_SELECTED_PAY_METHOD = 'SET_MT_SELECTED_PAY_METHOD';
export var SET_MT_WALLET_BAL = 'SET_MT_WALLET_BAL';
export var SET_MT_TRANS_STATE = 'SET_MT_TRANS_STATE';
export var SET_MT_ONLINE_BANKS = 'SET_MT_ONLINE_BANKS';
export var SET_MT_PREFERRED_BANK = 'SET_MT_PREFERRED_BANK';
export var SET_MT_CUS_CARDS = 'SET_MT_CUS_CARDS';
export var SET_MT_SELECTED_CARD = 'SET_MT_SELECTED_CARD';
export var SET_MT_CUS_EFT_LIST = 'SET_MT_CUS_EFT_LIST';
export var SET_MT_CUS_SELECTED_EFT = 'SET_MT_CUS_SELECTED_EFT';
export var SET_MT_FLINKS_DETAIL = 'SET_MT_FLINKS_DETAIL';
export var SET_MT_CLEAR_FLINKS_STATE = 'SET_MT_CLEAR_FLINKS_STATE';
export var SET_MT_TRANS_UNIQ_ID = 'SET_MT_TRANS_UNIQ_ID';
export var SET_MT_CHALLENGE_PARAMS = 'SET_MT_CHALLENGE_PARAMS';
export var SET_TABAPAY_RESPONSE = 'SET_TABAPAY_RESPONSE';
export var SET_MT_TRANS_INFO = 'SET_MT_TRANS_INFO';
export var SET_MT_REDIRECT_ROUTE = 'SET_MT_REDIRECT_ROUTE';
export var SET_MT_SPEED_OPTIONS = "SET_MT_SPEED_OPTIONS";
export var SET_MT_SPEED_DECIDED = "SET_MT_SPEED_DECIDED";
export var SET_MT_INSTANT_RATE = "SET_MT_INSTANT_RATE";
export var SET_MT_VERIFICATION_STATE = "SET_MT_VERIFICATION_STATE";
export var SET_PERSONAL_INFO_FORM = "SET_PERSONAL_INFO_FORM";
export var CLEAR_MT_BEN_STATE = 'CLEAR_MT_BEN_STATE';
export var SET_MT_DEBIT_API_ERROR = 'SET_MT_DEBIT_API_ERROR';
export var SET_MT_STEPS_AVAIL = 'SET_MT_STEPS_AVAIL';
export var SET_MT_OPEN_REQUEST_DIALOG = 'SET_MT_OPEN_REQUEST_DIALOG';
export var SET_FIRST_RECIPIENT = 'SET_FIRST_RECIPIENT';
export var SET_EDIT_CARD_INFO = 'SET_EDIT_CARD_INFO';
export var SET_MT_MANUAL_EFT_LIMIT = 'SET_MT_MANUAL_EFT_LIMIT';