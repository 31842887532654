import _defineProperty from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import transactionProcessingStatuses from '../../shared/const/transactionProcessingStatuses';
import { SET_WALLET_DEPOSIT_TRANSACTION_UNIQUE_ID, SET_WALLET_DEPOSIT_AMOUNT, SET_WALLET_DEPOSIT_PAYMENT_TYPE, SET_WALLET_DEPOSIT_EFT_INFO, SET_WALLET_DEPOSIT_TRANSACTION_PROCESSING_INFO, CLEAR_WALLET_DEPOSIT_TRAN_STATE, CLEAR_WALLET_DEPOSIT_PAYMENT_OPTION, CLEAR_WALLET_DEPOSIT_STATE, SET_WALLET_DEPOSIT_FLINKS_LOGS_ID, SET_WALLET_DEPOSIT_FLINKS_LOGIN_ID } from './actionTypes';
var initialState = {
  wallet_deposit_transaction_unique_id: '',
  wallet_deposit_amount: '',
  wallet_deposit_payment_type: '',
  wallet_deposit_eft: null,
  wallet_deposit_transaction_processing_info: {
    status: transactionProcessingStatuses.NOT_INITIATED,
    success_dialog_open: false,
    failed_dialog_open: false,
    required_level_to_upgrade: null,
    payment_type: null,
    bank_portal_link: 'https://www1.bmo.com/onlinebanking/cgi-bin/netbnx/NBmain?product=5'
  }
};
var walletDepositTransaction = function walletDepositTransaction() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_WALLET_DEPOSIT_TRANSACTION_UNIQUE_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        wallet_deposit_transaction_unique_id: action.payload
      });
    case SET_WALLET_DEPOSIT_AMOUNT:
      return _objectSpread(_objectSpread({}, state), {}, {
        wallet_deposit_amount: action.payload
      });
    case SET_WALLET_DEPOSIT_PAYMENT_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, {
        wallet_deposit_payment_type: action.payload
      });
    case SET_WALLET_DEPOSIT_EFT_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        wallet_deposit_eft: action.payload
      });
    case SET_WALLET_DEPOSIT_TRANSACTION_PROCESSING_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        wallet_deposit_transaction_processing_info: _objectSpread(_objectSpread({}, state.wallet_deposit_transaction_processing_info), action.payload)
      });
    case CLEAR_WALLET_DEPOSIT_TRAN_STATE:
      return _objectSpread(_objectSpread({}, initialState), {}, {
        wallet_deposit_transaction_processing_info: _objectSpread({}, state.wallet_deposit_transaction_processing_info)
      });
    case CLEAR_WALLET_DEPOSIT_PAYMENT_OPTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        payment_type: ''
      });
    case CLEAR_WALLET_DEPOSIT_STATE:
      return initialState;
    case SET_WALLET_DEPOSIT_FLINKS_LOGS_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        wallet_deposit_flinks_logs_id: action.payload
      });
    case SET_WALLET_DEPOSIT_FLINKS_LOGIN_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        wallet_deposit_flinks_login_id: action.payload
      });
    default:
      return state;
  }
};
export default walletDepositTransaction;