import _defineProperty from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import actionTypes from "./actionTypes";
var initState = function initState() {
  return {
    isAuthenticated: false,
    publicIdentifier: process.env.JWT_PUBLIC_IDENTIFIER,
    csrfToken: null,
    referral_code: null,
    hasPhoneNumber: false,
    isPhoneValidated: false,
    is2FAActive: false,
    is2FAPrompted: false,
    deviceUUID: typeof window !== 'undefined' && localStorage.getItem(actionTypes.DEVICE_UUID) || '',
    cp_session_token: null
  };
};
var loginSuccess = function loginSuccess(state, _ref) {
  var _ref$hasPhoneNumber = _ref.hasPhoneNumber,
    hasPhoneNumber = _ref$hasPhoneNumber === void 0 ? true : _ref$hasPhoneNumber,
    cp_session_token = _ref.cp_session_token,
    deviceUUID = _ref.deviceUUID,
    isPhoneValidated = _ref.isPhoneValidated,
    is2FAActive = _ref.is2FAActive;
  // To save deviceId separate than redux state
  if (deviceUUID) localStorage.setItem(actionTypes.DEVICE_UUID, deviceUUID);
  return _objectSpread(_objectSpread({}, state), {}, {
    publicIdentifier: null,
    isAuthenticated: true,
    referral_code: null,
    cp_session_token: cp_session_token,
    hasPhoneNumber: hasPhoneNumber,
    isPhoneValidated: isPhoneValidated,
    is2FAActive: is2FAActive,
    deviceUUID: deviceUUID || state.deviceUUID || localStorage.getItem(actionTypes.DEVICE_UUID)
  });
};
var clearCustomerState = function clearCustomerState(state) {
  return _objectSpread(_objectSpread({}, initState()), {}, {
    csrfToken: state.csrfToken,
    referral_code: state.referral_code
  });
};
var setHasPhoneNumber = function setHasPhoneNumber(state, _ref2) {
  var hasPhoneNumber = _ref2.hasPhoneNumber;
  return _objectSpread(_objectSpread({}, state), {}, {
    hasPhoneNumber: hasPhoneNumber
  });
};
var setPhoneValidated = function setPhoneValidated(state, _ref3) {
  var isPhoneValidated = _ref3.isPhoneValidated,
    cp_session_token = _ref3.cp_session_token;
  return _objectSpread(_objectSpread({}, state), {}, {
    isAuthenticated: true,
    publicIdentifier: null,
    isPhoneValidated: isPhoneValidated,
    cp_session_token: isPhoneValidated ? cp_session_token : state.cp_session_token
  });
};
var signUp = function signUp(state) {
  return _objectSpread(_objectSpread({}, state), {}, {
    // publicIdentifier: null,
    isAuthenticated: false,
    referral_code: null
  });
};
var authReducers = function authReducers() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initState();
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
      return loginSuccess(state, action.payload);
    case actionTypes.SIGN_UP_SUCCESS:
      return signUp(state);
    case actionTypes.CSRF_TOKEN:
      return _objectSpread(_objectSpread({}, state), {}, {
        csrfToken: action.payload.csrfToken
      });
    case actionTypes.SET_REFERRAL_CODE:
      return _objectSpread(_objectSpread({}, state), {}, {
        referral_code: action.payload.referral_code
      });
    case actionTypes.CLEAR_STATE:
      return clearCustomerState(state);
    case actionTypes.SET_HAS_PHONE_NUMBER:
      return setHasPhoneNumber(state, action.payload);
    case actionTypes.SET_PHONE_VALIDATED:
      return setPhoneValidated(state, action.payload);
    case actionTypes.DEVICE_UUID:
      return _objectSpread(_objectSpread({}, state), {}, {
        deviceUUID: action.payload
      });
    case actionTypes.SET_2FA_PROMPT:
      return _objectSpread(_objectSpread({}, state), {}, {
        is2FAPrompted: action.payload
      });
    default:
      return state;
  }
};
export default authReducers;