import _defineProperty from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SET_COUNTRY_ID, SET_COUNTRY_CODE, SET_COUNTRY_CALLING_CODE, SET_RECIPIENT_PHONE_NUMBER, SET_SELECTED_OPERATOR, SET_PRODUCTS, SET_SELECTED_PRODUCT, CLEAR_MOBILE_RC_STATE_DT, SET_AVAILABLE_OPERATORS, SET_PRODUCT_AMOUNT, SET_REQUIRED_FIELDS, SET_REQ_FIELD_INFOS, SET_ACTIVE_TAB, SET_NOT_IN_RANGE_MSG, SET_CONVERSION_LOADING, SET_DEFAULT_COUNTRY_MOB } from './actionTypes';
var initialState = {
  serviceId: 1,
  countryId: null,
  countryCode: null,
  countryCallingCode: null,
  recipientPhoneNumber: null,
  selectedOperator: null,
  products: null,
  selectedProduct: null,
  availableOperators: null,
  requiredFields: [],
  reqFieldInfos: null,
  productAmount: null,
  activeTab: 0,
  notInRangeMessage: '',
  conversionLoading: false,
  defaultCountry: null
};
var reducer = function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_COUNTRY_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        countryId: action.payload
      });
    case SET_COUNTRY_CODE:
      return _objectSpread(_objectSpread({}, state), {}, {
        countryCode: action.payload
      });
    case SET_COUNTRY_CALLING_CODE:
      return _objectSpread(_objectSpread({}, state), {}, {
        countryCallingCode: action.payload
      });
    case SET_RECIPIENT_PHONE_NUMBER:
      return _objectSpread(_objectSpread({}, state), {}, {
        recipientPhoneNumber: action.payload
      });
    case SET_SELECTED_OPERATOR:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedOperator: action.payload
      });
    case SET_PRODUCTS:
      return _objectSpread(_objectSpread({}, state), {}, {
        products: action.payload
      });
    case SET_SELECTED_PRODUCT:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedProduct: action.payload
      });
    case SET_AVAILABLE_OPERATORS:
      return _objectSpread(_objectSpread({}, state), {}, {
        availableOperators: action.payload
      });
    case SET_PRODUCT_AMOUNT:
      return _objectSpread(_objectSpread({}, state), {}, {
        productAmount: action.payload
      });
    case SET_REQUIRED_FIELDS:
      return _objectSpread(_objectSpread({}, state), {}, {
        requiredFields: action.payload
      });
    case SET_REQ_FIELD_INFOS:
      return _objectSpread(_objectSpread({}, state), {}, {
        reqFieldInfos: action.payload
      });
    case SET_ACTIVE_TAB:
      return _objectSpread(_objectSpread({}, state), {}, {
        activeTab: action.payload
      });
    case SET_NOT_IN_RANGE_MSG:
      return _objectSpread(_objectSpread({}, state), {}, {
        notInRangeMessage: action.payload
      });
    case SET_CONVERSION_LOADING:
      return _objectSpread(_objectSpread({}, state), {}, {
        conversionLoading: action.payload
      });
    case SET_DEFAULT_COUNTRY_MOB:
      return _objectSpread(_objectSpread({}, state), {}, {
        defaultCountry: action.payload
      });
    case CLEAR_MOBILE_RC_STATE_DT:
      return initialState;
    default:
      return state;
  }
};
export default reducer;