import React from 'react';
import {
    crispScript, gtmScript, mixpanelScript
} from '../../settings/analytics';
import { facebookScript } from '../../settings/fbq';

const isProd = process.env.REACT_APP_ENV === 'prod';

const config = {
    dev: {},
    staging: {
        gtmId: 'GTM-5X5R7BCS', //'GTM-T7CDXL2';
    },
    prod: {
        facebookId: '1691521041079574',
        gtmId: 'GTM-WP9Q2P',
        gadsId: 'AW-944151606',
        mixpanel: process.env.MIXPANEL_API_KEY
    },
}

export const FacebookScriptAnalytics = () => {
    return (
        <>
            {facebookScript()}
        </>
    )
}

const AnalyticsHeader = () => {
    if (isProd) {
        return (
            <>
                {/* {datadogScript()} */}
                {/* {gtmScript(config.prod.gtmId)} */}
                {crispScript()}
                {mixpanelScript(config.prod.mixpanel)}
            </>
        )
    } else {
        return (
            <>
                {/* {datadogScript()} */}
                {gtmScript(config.staging.gtmId)}
                {mixpanelScript(config.prod.mixpanel)}
                {crispScript()}
                {/* {sendinblueScript()} */}
            </>
        )
    }
}

export default AnalyticsHeader