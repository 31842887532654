export default {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  CSRF_TOKEN: 'CSRF_TOKEN',
  DEVICE_UUID: 'DEVICE_UUID',
  CLEAR_STATE: 'CLEAR_STATE',
  SET_REFERRAL_CODE: 'SET_REFERRAL_CODE',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  TWO_FACTOR_LOGIN: 'TWO_FACTOR_LOGIN',
  SET_HAS_PHONE_NUMBER: 'SET_HAS_PHONE_NUMBER',
  SET_PHONE_VALIDATED: 'SET_PHONE_VALIDATED',
  SET_BUSINESS_INFO: 'SET_BUSINESS_INFO',
  SET_2FA_PROMPT: 'SET_2FA_PROMPT'
};