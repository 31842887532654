import _defineProperty from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import client from "./client";
import { graphqlError } from "../../../../shared/utility";
import { store } from '../../../../redux/store';
import * as Sentry from '@sentry/nextjs';
import { BrowserLogger } from "../../../browser-logger-v2";
export var apiMainMutation = function apiMainMutation(mutation, variables) {
  return new Promise(function (resolve) {
    var objToBeReturned = {
      success: true,
      errorMessage: null,
      data: null
    };
    client.mutate({
      mutation: mutation,
      variables: variables
    }).then(function (_ref) {
      var data = _ref.data;
      objToBeReturned.data = data;
      resolve(objToBeReturned);
    })["catch"](function (err) {
      var errorMessage = graphqlError(err);
      var _ref2 = store && store.getState() || {},
        customer = _ref2.customer;
      objToBeReturned = _objectSpread(_objectSpread({}, objToBeReturned), {}, {
        success: false,
        errorMessage: errorMessage
      });

      // Backend specific errors do not need to be logged in Sentry/Datadog
      if (!errorMessage.includes('Our records indicate that you already have an account with us') || !errorMessage.includes('Unable to complete your transaction') || !errorMessage.includes('The login credentials you entered are invalid') || !errorMessage.includes('Declined by your bank') || !errorMessage.includes('Promotion is already used')) {
        BrowserLogger.error(err, {
          action_from: 'apiMainMutation',
          cus_unique_id: customer === null || customer === void 0 ? void 0 : customer.cus_unique_id
        });
        Sentry.captureException(err, function (scope) {
          var _mutation$definitions, _mutation$definitions2;
          scope.clear();
          scope.setContext("additionalData", {
            cus_unique_id: customer === null || customer === void 0 ? void 0 : customer.cus_unique_id,
            mutation: mutation && (mutation === null || mutation === void 0 ? void 0 : mutation.definitions) && (mutation === null || mutation === void 0 || (_mutation$definitions = mutation.definitions) === null || _mutation$definitions === void 0 ? void 0 : _mutation$definitions.length) > 0 && (mutation === null || mutation === void 0 ? void 0 : mutation.definitions[0]['name']) && (mutation === null || mutation === void 0 || (_mutation$definitions2 = mutation.definitions[0]['name']) === null || _mutation$definitions2 === void 0 ? void 0 : _mutation$definitions2.value)
          });
          return scope;
        });
      }
      resolve(objToBeReturned);
    });
  });
};