export var SET_BALANCE_FLOW_TYPE = 'SET_BALANCE_FLOW_TYPE';
export var SET_BAL_COMPLIANCE = 'SET_BALANCE_COMPLIANCE';
export var SET_BAL_WITHDRAWAL_AMOUNT = 'SET_BAL_WITHDRAWAL_AMOUNT';
export var SET_BAL_WITHDRAWAL_AVAIL_PAY_TYPE = 'SET_BAL_WITHDRAWAL_AVAIL_PAY_TYPE';
export var SET_BAL_WITHDRAWAL_SELECTED_PAY_TYPE = 'SET_BAL_WITHDRAWAL_SELECTED_PAY_TYPE';
export var SET_BAL_WITHDRAWAL_TRANS_ID = 'SET_BAL_WITHDRAWAL_TRANS_ID';
export var SET_BAL_WITHDRAWAL_TRANS_INFO = 'SET_BAL_WITHDRAWAL_TRANS_INFO';
export var SET_BAL_DEPOSIT_AMOUNT = 'SET_BAL_DEPOSIT_AMOUNT';
export var SET_BAL_DEPOSIT_AVAIL_PAY_TYPE = 'SET_BAL_DEPOSIT_AVAIL_PAY_TYPE';
export var SET_BAL_DEPOSIT_SELECTED_PAY_TYPE = 'SET_BAL_DEPOSIT_SELECTED_PAY_TYPE';
export var SET_BAL_DEPOSIT_TRANS_ID = 'SET_BAL_DEPOSIT_TRANS_ID';
export var SET_BAL_DEPOSIT_TRANS_INFO = 'SET_BAL_DEPOSIT_TRANS_INFO';
export var SET_BAL_DEPOSIT_CUS_EFT_BANK = 'SET_BAL_DEPOSIT_CUS_EFT_BANK';
export var SET_BAL_DEPSOT_AVAIL_EFT_BANKS = 'SET_BAL_DEPSOT_AVAIL_EFT_BANKS';
export var SET_BAL_DEPOSIT_FLINKS = 'SET_BAL_DEPOSIT_FLINKS';
export var CLEAR_DEPOSIT_FLINKS = 'CLEAR_DEPOSIT_FLINKS';
export var SET_BAL_DEPOSIT_PREFERRED_BANK = 'SET_BAL_DEPOSIT_PREFERRED_BANK';
export var CLEAR_BAL_DEPOSIT_STATE = 'CLEAR_BAL_DEPOSIT_STATE';
export var SET_BAL_WITHDRAW_PREFERRED_BANK = 'SET_BAL_WITHDRAW_PREFERRED_BANK';
export var SET_BAL_WITHDRAW_VERIFICATION_CODE = 'SET_BAL_WITHDRAW_VERIFICATION_CODE';
export var SET_WALLET_BALANCE = 'SET_WALLET_BALANCE';
export var SET_BAL_MANUAL_EFT_LIMIT = 'SET_BAL_MANUAL_EFT_LIMIT';