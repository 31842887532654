import _toConsumableArray from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SET_RECEIVING_METHOD, SET_BEN_PAYMENT_METHOD, SET_BEN_PAYMENT_METHOD_OPTION, SET_BEN_PAYMENT_METHOD_DETAILS, SET_BEN_PAYMENT_OPTION_GROUP, CLEAR_BEN_STATE, CLEAR_RECEIVING_METHOD, SET_BEN_COUNTRY_ID, SET_BEN_CURRENCY, SET_OPEN_REQUEST_DIALOG, FETCH_BENEFICIARY, UPDATE_HAS_ONLY_PRIORITY, UPDATE_BENEFICIARY_PAYMENT_INFOS_LIST, ADD_ADDITIONAL_FIELDS_CHILD, SET_RECIPIENT_STATUS, SET_BENEFICIARY_GROUP, SET_ADD_NEW_RECIPIENT_FORM, SET_RECIPIENT_SUCCESS_DIALOG, SET_RECIPIENT_CURRENT_FLOW, SET_RECIPIENT_DEL_SUCCESS_DIALOG } from './actionTypes';
import authActionTypes from '../auth/actionTypes';
import { updateObject } from '../../shared/utility';
var initialState = {
  benCountryId: null,
  benCurrency: null,
  hasOnlyPriority: false,
  loading: false,
  receivingMethod: {},
  benCountryPlatform: null,
  paymentOptionGroup: 'FIRST',
  beneficiaryGroup: 'FIRST',
  beneficiary: {},
  fees: 0.00,
  beneficiary_payment_info: {
    ben_payment_method: null,
    ben_location_id: null,
    ben_bank_id: null,
    ben_bank_processing_method: 'REGULAR',
    ben_brc: null,
    ben_bsb: null,
    ben_bank_account_number: null,
    ben_bank_code_manual: null,
    ben_bank_name_manual: null,
    ben_bank_branch_code_manual: null
  },
  additionalFieldChildren: [],
  recipientStatus: '',
  requestDialog: false,
  addNewRecipientForm: {},
  recipientSuccessDialog: false,
  currentFlow: null,
  deletedRecipient: null
};
var setBenPaymentMethodOption = function setBenPaymentMethodOption(state, _ref) {
  var paymentMethodOption = _ref.paymentMethodOption;
  return updateObject(state, {
    paymentMethodOption: paymentMethodOption
  });
};
var setBenPaymentOptionGroup = function setBenPaymentOptionGroup(state, _ref2) {
  var paymentOptionGroup = _ref2.paymentOptionGroup;
  return updateObject(state, {
    paymentOptionGroup: paymentOptionGroup
  });
};
var setBeneficiaryGroup = function setBeneficiaryGroup(state, _ref3) {
  var beneficiaryGroup = _ref3.beneficiaryGroup;
  return updateObject(state, {
    beneficiaryGroup: beneficiaryGroup
  });
};
var setBenPaymentMethodDetails = function setBenPaymentMethodDetails(state, _ref4) {
  var paymentMethodDetails = _ref4.paymentMethodDetails;
  return updateObject(state, {
    paymentMethodDetails: paymentMethodDetails
  });
};
var setReceivingMethod = function setReceivingMethod(state, _ref5) {
  var receivingMethod = _ref5.receivingMethod;
  var receivingMethodKey = "".concat(Object.keys(receivingMethod)[0]);
  return updateObject(state, {
    receivingMethod: receivingMethod,
    beneficiary_payment_info: updateObject(state.beneficiary_payment_info, {
      ben_payment_method: receivingMethodKey
    })
  });
};
var setBenPaymentMethod = function setBenPaymentMethod(state, _ref6) {
  var beneficiary_payment_info = _ref6.beneficiary_payment_info;
  return updateObject(state, {
    beneficiary_payment_info: updateObject(state.beneficiary_payment_info, beneficiary_payment_info)
  });
};
var setBenCountryId = function setBenCountryId(state, _ref7) {
  var benCountryId = _ref7.benCountryId,
    platform = _ref7.platform;
  var benPaymentInfo = initialState.beneficiary_payment_info;
  var receivingMethod = initialState.receivingMethod;
  if (state && state.receivingMethod && state.beneficiary_payment_info && Object.keys(state.beneficiary_payment_info).length && Object.keys(state.receivingMethod).length) {
    benPaymentInfo = state.beneficiary_payment_info;
    receivingMethod = state.receivingMethod;
  }
  return updateObject(state, {
    benCountryId: benCountryId,
    benCountryPlatform: platform,
    beneficiary_payment_info: benPaymentInfo,
    receivingMethod: receivingMethod
  });
};
var setBenCurrency = function setBenCurrency(state, _ref8) {
  var benCurrency = _ref8.benCurrency;
  return updateObject(state, {
    benCurrency: benCurrency
  });
};
var setRecipientStatus = function setRecipientStatus(state, _ref9) {
  var recipientStatus = _ref9.recipientStatus;
  return updateObject(state, {
    recipientStatus: recipientStatus
  });
};
var setOpenRequestDialog = function setOpenRequestDialog(state, _ref10) {
  var requestDialog = _ref10.requestDialog;
  return updateObject(state, {
    requestDialog: requestDialog
  });
};
var clearState = function clearState(state) {
  return updateObject(state, initialState);
};
var fetchBeneficiary = function fetchBeneficiary(state, _ref11) {
  var beneficiary = _ref11.beneficiary;
  return updateObject(state, {
    beneficiary: beneficiary,
    benCountryId: beneficiary.ben_country
  });
};
var updateBeneficiaryPaymentInfosList = function updateBeneficiaryPaymentInfosList(state, _ref12) {
  var beneficiary_payment_infos = _ref12.beneficiary_payment_infos;
  return updateObject(state, {
    beneficiary: updateObject(state.beneficiary, {
      beneficiary_payment_infos: beneficiary_payment_infos
    })
  });
};
var updateHasOnlyPriority = function updateHasOnlyPriority(state, _ref13) {
  var hasOnlyPriority = _ref13.hasOnlyPriority;
  return updateObject(state, {
    hasOnlyPriority: hasOnlyPriority
  });
};
var reducer = function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_RECEIVING_METHOD:
      return setReceivingMethod(state, action.payload);
    case SET_BEN_PAYMENT_METHOD:
      return setBenPaymentMethod(state, action.payload);
    case SET_BEN_PAYMENT_METHOD_OPTION:
      return setBenPaymentMethodOption(state, action.payload);
    case SET_BEN_PAYMENT_OPTION_GROUP:
      return setBenPaymentOptionGroup(state, action.payload);
    case SET_BENEFICIARY_GROUP:
      return setBeneficiaryGroup(state, action.payload);
    case SET_BEN_PAYMENT_METHOD_DETAILS:
      return setBenPaymentMethodDetails(state, action.payload);
    case SET_BEN_COUNTRY_ID:
      return setBenCountryId(state, action.payload);
    case SET_OPEN_REQUEST_DIALOG:
      return setOpenRequestDialog(state, action.payload);
    case SET_BEN_CURRENCY:
      return setBenCurrency(state, action.payload);
    case SET_RECIPIENT_STATUS:
      return setRecipientStatus(state, action.payload);
    case CLEAR_BEN_STATE:
      return clearState(state);
    case CLEAR_RECEIVING_METHOD:
      return _objectSpread(_objectSpread({}, state), {}, {
        receivingMethod: {}
      });
    case authActionTypes.CLEAR_STATE:
      return clearState(state);
    case FETCH_BENEFICIARY:
      return fetchBeneficiary(state, action.payload);
    case UPDATE_HAS_ONLY_PRIORITY:
      return updateHasOnlyPriority(state, action.payload);
    case UPDATE_BENEFICIARY_PAYMENT_INFOS_LIST:
      return updateBeneficiaryPaymentInfosList(state, action.payload);
    case ADD_ADDITIONAL_FIELDS_CHILD:
      return _objectSpread(_objectSpread({}, state), {}, {
        additionalFieldChildren: [].concat(_toConsumableArray(state.additionalFieldChildren), [action.payload])
      });
    case SET_ADD_NEW_RECIPIENT_FORM:
      return _objectSpread(_objectSpread({}, state), {}, {
        addNewRecipientForm: action.payload
      });
    case SET_RECIPIENT_SUCCESS_DIALOG:
      return _objectSpread(_objectSpread({}, state), {}, {
        recipientSuccessDialog: action.payload
      });
    case SET_RECIPIENT_CURRENT_FLOW:
      return _objectSpread(_objectSpread({}, state), {}, {
        currentFlow: action.payload
      });
    case SET_RECIPIENT_DEL_SUCCESS_DIALOG:
      return _objectSpread(_objectSpread({}, state), {}, {
        deletedRecipient: action.payload
      });
    default:
      return state;
  }
};
export default reducer;