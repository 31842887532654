export var SET_ORIGINAL_COUNTRY_ID = 'SET_ORIGINAL_COUNTRY_ID';
export var SET_CONVERTED_COUNTRY_ID = 'SET_CONVERTED_COUNTRY_ID';
export var SET_EXCHANGE_AMOUNT = 'SET_EXCHANGE_AMOUNT';
export var SET_CONVERTED_AMOUNT = 'SET_CONVERTED_AMOUNT';
export var SET_EXCHANGE_RATE = 'SET_EXCHANGE_RATE';
export var SET_PROMO_RATE = 'SET_PROMO_RATE';
export var SET_SPECIAL_RATE = 'SET_SPECIAL_RATE';
export var SET_ORIGINAL_CURRENCY_EFT_INFO_ID = 'SET_ORIGINAL_CURRENCY_EFT_INFO_ID';
export var SET_CONVERTED_CURRENCY_EFT_INFO_ID = 'SET_CONVERTED_CURRENCY_EFT_INFO_ID';
export var SET_ORIGINAL_CURRENCY_FLINKS_LOGIN_ID = 'SET_ORIGINAL_CURRENCY_FLINKS_LOGIN_ID';
export var SET_CONVERTED_CURRENCY_FLINKS_LOGIN_ID = 'SET_CONVERTED_CURRENCY_FLINKS_LOGIN_ID';
export var SET_ORIGINAL_CURRENCY_FLINKS_LOGS_ID = 'SET_ORIGINAL_CURRENCY_FLINKS_LOGS_ID';
export var SET_CONVERTED_CURRENCY_FLINKS_LOGS_ID = 'SET_CONVERTED_CURRENCY_FLINKS_LOGS_ID';
export var SET_ORIGINAL_CURRENCY_EFT_INFO = 'SET_ORIGINAL_CURRENCY_EFT_INFO';
export var SET_CONVERTED_CURRENCY_EFT_INFO = 'SET_CONVERTED_CURRENCY_EFT_INFO';
export var CLEAR_EXCHANGE_STATE = 'CLEAR_EXCHANGE_STATE';
export var SET_CURRENCY_EXCHANGE_PROCESSING_INFO = 'SET_CURRENCY_EXCHANGE_PROCESSING_INFO';
export var SET_PAYMENT_BANK_INFO = 'SET_PAYMENT_BANK_INFO';
export var SET_DEPOSIT_BANK_INFO = 'SET_DEPOSIT_BANK_INFO';
export var SET_EXCHANGE_TRANSFER_PURPOSE = 'SET_EXCHANGE_TRANSFER_PURPOSE';
export var SET_EXCHANGE_FLINKS_DEPOSIT_REQUEST = 'SET_EXCHANGE_FLINKS_DEPOSIT_REQUEST';
export var SET_EXCHANGE_FLINKS_PAYMENT_REQUEST = 'SET_EXCHANGE_FLINKS_PAYMENT_REQUEST';
export var SET_CURRENCY_EXCHANGE_MANUAL_PAYMENT_FLOW = 'SET_CURRENCY_EXCHANGE_MANUAL_PAYMENT_FLOW';
export var SET_CURRENCY_EXCHANGE_PROCESSING_MANUAL_FLOW = 'SET_CURRENCY_EXCHANGE_PROCESSING_MANUAL_FLOW';
export var SET_DEPOSIT_DATE = 'SET_DEPOSIT_DATE';