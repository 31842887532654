/**
 * @constant specialCharactersRegex
 * @description Is a regex used to verify if have any special characters in a string.
 */
export var specialCharactersRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
export var anyOneSpecialCharactersRegex = /^(?=.*[~`!@#$%^&*()_\-+={[}\]|\:;"'<,>.?\/]).*.$/;

// Characters that will be allowed on all the TextFields
export var alphaCharactersAllowed = /[^A-Za-z ]/g;
export var currencyAmountAllowed = /[^0-9^.]+$/g;
export var numbersAllowed = /[^0-9]+$/g;
export var emailCharactersAllowed = /[^A-Za-z0-9@._ -]/g;
export var emailRegex = /^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
export var alphaNumericCharactersAllowed = /[^A-Za-z0-9, -]/g;
export var phoneNumberAllowed = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?(([0-9]| ){0,7})$/;
export var alphaCharactersWithNumbersAllowed = /[^A-Za-z0-9 ]/g;
export var alphaCharactersWithNumbersAndComaAllowed = /[^A-Za-z0-9, ]/g;
export var alphaCharactersWithNumbersAndDecimal = /[^A-Za-z0-9. ]/g;
export var remitbeeEmailNotAllowed = /^((?!remitbee).)*$/;
export var currencyMarkdownRegex = /({{[A-Za-z]{3}}})/;
export var currencyRegex = /[A-Za-z]{3}/;
export var alphaCharactersRegex = /^(?=.*[A-Z]).*.$/;
export var lowerCharactersRegex = /^(?=.*[a-z]).*.$/;
export var numCharactersRegex = /^(?=.*[0-9]).*.$/;
export var requireNonSpace = /.*\S.*/;
export var postalCode = /^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/;
export var phoneNumber = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export var australiaPostalCode = /^[0-9]{4}$/;
export var bankAccount = /(^([a-zA-Z0-9]+)$)?(\d{1})?/;
export var bankAccountNumber = /^[0-9]{1,50}$/;
export var indianBankAccountNumber = /^[0-9]{1,50}$/;
export var debitCardCVVNumber = /^[0-9]{1,50}$/;
export var website = /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
/**
 * @const relativePathRegex
 * @description It verifies if a relative path it has only one /.
 */
export var relativePathRegex = /^\/[^\/\\]/;
/**
 * @const relativePathRegex
 * @description It verifies if a string containing only numbers matches determined length /.
 */
export var numbersWithMinMaxLength = function numbersWithMinMaxLength(minLength, maxLength) {
  return new RegExp("^[0-9]{".concat(minLength, ",").concat(maxLength, "}$"));
};