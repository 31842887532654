import _defineProperty from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SET_BALANCE_FLOW_TYPE, SET_BAL_COMPLIANCE, SET_BAL_WITHDRAWAL_AMOUNT, SET_BAL_WITHDRAWAL_AVAIL_PAY_TYPE, SET_BAL_WITHDRAWAL_SELECTED_PAY_TYPE, SET_BAL_WITHDRAWAL_TRANS_INFO, SET_BAL_DEPOSIT_AMOUNT, SET_BAL_DEPOSIT_AVAIL_PAY_TYPE, SET_BAL_DEPOSIT_SELECTED_PAY_TYPE, SET_BAL_DEPOSIT_TRANS_INFO, SET_BAL_DEPOSIT_CUS_EFT_BANK, SET_BAL_DEPSOT_AVAIL_EFT_BANKS, SET_BAL_DEPOSIT_FLINKS, CLEAR_DEPOSIT_FLINKS, SET_BAL_DEPOSIT_PREFERRED_BANK, CLEAR_BAL_DEPOSIT_STATE, SET_BAL_WITHDRAW_PREFERRED_BANK, SET_BAL_WITHDRAW_VERIFICATION_CODE, SET_WALLET_BALANCE, SET_BAL_MANUAL_EFT_LIMIT } from './actionTypes';
var initialState = {
  type: null,
  balance: null,
  withdrawal_amount: null,
  withdrawal_trans_unique_id: null,
  withdrawal_payment_type: null,
  withdrawal_payment_types: [],
  withdrawal_trans_info: null,
  withdrawal_preferred_bank: null,
  deposit_amount: null,
  deposit_payment_types: [],
  deposit_payment_type: null,
  deposit_trans_info: null,
  deposit_selected_eft_bank: null,
  deposit_avail_eft_banks: [],
  deposit_flinks: null,
  deposit_preferred_bank: null,
  withdrawal_verification_code: null,
  wallet_balance: null,
  manual_eft_limit: null
};
var reducer = function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_BALANCE_FLOW_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, {
        type: action.payload
      });
    case SET_BAL_COMPLIANCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        compliance: action.payload
      });
    case SET_BAL_WITHDRAWAL_AMOUNT:
      return _objectSpread(_objectSpread({}, state), {}, {
        withdrawal_amount: action.payload
      });
    case SET_BAL_WITHDRAWAL_AVAIL_PAY_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, {
        withdrawal_payment_types: action.payload
      });
    case SET_BAL_WITHDRAWAL_SELECTED_PAY_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, {
        withdrawal_payment_type: action.payload
      });
    case SET_BAL_WITHDRAWAL_TRANS_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        withdrawal_trans_info: action.payload
      });
    case SET_BAL_DEPOSIT_AMOUNT:
      return _objectSpread(_objectSpread({}, state), {}, {
        deposit_amount: action.payload
      });
    case SET_BAL_DEPOSIT_AVAIL_PAY_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, {
        deposit_payment_types: action.payload
      });
    case SET_BAL_DEPOSIT_SELECTED_PAY_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, {
        deposit_payment_type: action.payload
      });
    case SET_BAL_DEPOSIT_TRANS_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        deposit_trans_info: action.payload
      });
    case SET_BAL_DEPOSIT_CUS_EFT_BANK:
      return _objectSpread(_objectSpread({}, state), {}, {
        deposit_selected_eft_bank: action.payload
      });
    case SET_BAL_DEPSOT_AVAIL_EFT_BANKS:
      return _objectSpread(_objectSpread({}, state), {}, {
        deposit_avail_eft_banks: action.payload
      });
    case SET_BAL_DEPOSIT_FLINKS:
      return _objectSpread(_objectSpread({}, state), {}, {
        deposit_flinks: action.payload
      });
    case SET_BAL_DEPOSIT_PREFERRED_BANK:
      return _objectSpread(_objectSpread({}, state), {}, {
        deposit_preferred_bank: action.payload
      });
    case SET_BAL_WITHDRAW_PREFERRED_BANK:
      return _objectSpread(_objectSpread({}, state), {}, {
        withdrawal_preferred_bank: action.payload
      });
    case SET_BAL_WITHDRAW_VERIFICATION_CODE:
      return _objectSpread(_objectSpread({}, state), {}, {
        withdrawal_verification_code: action.payload
      });
    case SET_WALLET_BALANCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        wallet_balance: action.payload
      });
    case CLEAR_DEPOSIT_FLINKS:
      return _objectSpread(_objectSpread({}, state), {}, {
        deposit_flinks: null
      });
    case CLEAR_BAL_DEPOSIT_STATE:
      return initialState;
    case SET_BAL_MANUAL_EFT_LIMIT:
      return _objectSpread(_objectSpread({}, state), {}, {
        manual_eft_limit: action.payload
      });
    default:
      return state;
  }
};
export default reducer;