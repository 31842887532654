import _defineProperty from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import persistState from 'redux-localstorage';
import reducers from './reducers';
var store = null;
var isClient = typeof window !== 'undefined';
var middlewares = [thunk, promise];
var statesToPersist = ['auth', 'customer', 'exchange', 'transaction', 'cancelTransaction', 'recipient', 'system', 'flinks', 'company', 'business', 'walletDepositTransaction', 'walletWithdrawalTransaction', 'questionnaire', 'paymentStatus', 'moneyTransfer', 'mobileTopUp', 'internationalBills', 'giftCards', 'dt_transaction', 'escalation', 'balance', 'currencyExchange', 'verification', 'esim'];

/** Redux tools extension for you to be able to see the state on the browser (Chrome).
 * It should be available only on the dev environment. */
var reduxDevToolsCompose = process.browser && window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.REACT_APP_ENV === 'dev' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null;
var composeEnhancers = reduxDevToolsCompose || compose;
var makeStore = function makeStore(initialState) {
  var enhancers = [applyMiddleware.apply(void 0, middlewares)];
  if (isClient) enhancers.push(persistState(statesToPersist)); // Only use localstorage persist state on client

  store = createStore(combineReducers(_objectSpread({}, reducers)), initialState, composeEnhancers.apply(void 0, enhancers));
  return store;
};
export { store, makeStore };