import _toConsumableArray from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { ADD_CARD, UPDATE_ATTEMPTS_BY_CARD_ID, REMOVE_CARD, UPDATE_ATTEMPTS_BY_DTONE_CARD_ID, REMOVE_DTONE_CARD } from './actionTypes';
var initialState = {
  cards: []
};
var reducer = function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ADD_CARD:
      var newCard = _objectSpread(_objectSpread({}, action.payload), {}, {
        attempts: 0
      });
      var isCardExists = state.cards.some(function (card) {
        return card.card_last_four_digits === action.payload.card_last_four_digits;
      });
      if (!isCardExists) {
        return _objectSpread(_objectSpread({}, state), {}, {
          cards: [].concat(_toConsumableArray(state.cards), [newCard])
        });
      }
      return state;
    case UPDATE_ATTEMPTS_BY_CARD_ID:
      var updatedCardsAttempts = state.cards.map(function (card) {
        return card.card_last_four_digits === action.payload ? _objectSpread(_objectSpread({}, card), {}, {
          attempts: card.attempts + 1
        }) : card;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        cards: updatedCardsAttempts
      });
    case UPDATE_ATTEMPTS_BY_DTONE_CARD_ID:
      var updatedDtoneCardsAttempts = state.cards.map(function (card) {
        return card.cardInfo === action.payload ? _objectSpread(_objectSpread({}, card), {}, {
          attempts: card.attempts + 1
        }) : card;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        cards: updatedDtoneCardsAttempts
      });
    case REMOVE_DTONE_CARD:
      var updatedDtoneCardsAfterRemove = state.cards.filter(function (card) {
        return card.cardInfo !== action.payload;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        cards: updatedDtoneCardsAfterRemove
      });
    case REMOVE_CARD:
      var updatedCardsAfterRemove = state.cards.filter(function (card) {
        return card.card_last_four_digits !== action.payload;
      });
      return _objectSpread(_objectSpread({}, state), {}, {
        cards: updatedCardsAfterRemove
      });
    default:
      return state;
  }
};
export default reducer;