import _defineProperty from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/* eslint-disable no-undef */
export var GA_TRACKING_ID = process.env.REACT_APP_ENV === 'prod' ? 'G-57ZJ2XBXFT' : 'G-8257L8SMN6'; //'GTM-T7CDXL2';
export var GADS_TRACKING_ID = 'AW-944151606';
var SERVER_CONTAINER = "https://".concat(process.env.CP_URL, "/gtmmetrics");

// const actions = {
//   'signup': 'TJMbCMzGiZIBELa4msID',
//   'transaction': '_zQICOHnk5IBELa4msID',
// }

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export var pageview = function pageview(url) {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('config', GA_TRACKING_ID, {
      page_path: url,
      server_container_url: "".concat(SERVER_CONTAINER)
    });
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export var event = function event(_ref) {
  var action = _ref.action,
    category = _ref.category,
    label = _ref.label,
    value = _ref.value,
    _ref$payload = _ref.payload,
    payload = _ref$payload === void 0 ? {} : _ref$payload;
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', action, _objectSpread({
      event_category: category,
      event_label: label,
      value: value
    }, payload));
  }
};

// export const gtmTracker = <>
//   <script async src="https://www.googletagmanager.com/gtag/js?id=AW-944151606"></script>
//   <script dangerouslySetInnerHTML={{
//     __html: `function gtag(){dataLayer.push(arguments)}window.dataLayer=window.dataLayer||[],gtag("js",new Date),gtag("config","AW-944151606",{page_path:window.location.pathname})`,
//   }}/>
// </>

// // Signup conversion
// export const gtagReportSignup = function() {
//   return new Promise(resolve => {
//     if(gtag) {
//       gtag('event', 'conversion', {
//           'send_to': 'AW-944151606/TJMbCMzGiZIBELa4msID',
//           'event_callback': resolve
//       });
//     } else resolve();
//   })
// }
// // Transaction conversion
// export const gtagReportTransaction = function() {
//   return new Promise(resolve => {
//     if(gtag) {
//       gtag('event', 'conversion', {
//           'send_to': 'AW-944151606/_zQICOHnk5IBELa4msID',
//           'transaction_id': '',
//           'event_callback': resolve
//       });
//     } else resolve();
//   })
// }