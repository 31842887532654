import _defineProperty from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SET_CE_RECEIVING_AMOUNT, SET_CE_RECEIVING_CURRENCY_INFO, SET_CE_RECEIVING_CURRENCY_RATE_DETAILS, SET_CE_SENDING_AMOUNT, SET_CE_SENDING_CURRENCY_INFO, SET_CE_SENDING_CURRENCY_RATE_DETAILS, SET_CE_COMPLIANCE, CLEAR_CE_STATE, SET_CE_TRANSFER_PURPOSE, SET_CE_CUS_EFT_LIST, SET_CE_WALLET_BALANCE, SET_CE_TRANSFER_AMOUNT, SET_CE_PAYMENT_METHOD, SET_CE_PAYMENT_EFT_INFO, SET_CE_PAYMENT_CONNECTION_TYPE, SET_CE_PAYMENT_BANKS, SET_CE_PAYMENT_FLINKS_INFO, SET_CE_DEPOSIT_EFT_INFO, SET_CE_DEPOSIT_CONNECTION_TYPE, SET_CE_DEPOSIT_METHOD, SET_CE_DEPOSIT_CUS_EFT_LIST, SET_CE_DEPOSIT_FLINKS_INFO, CLEAR_CE_DEPOSIT_FLINKS_INFO, CLEAR_CE_PAYMENT_FLINKS_INFO, SET_CE_RATE_DETAILS, SET_CE_NEW_MANUAL_CONNECTION, SET_CE_DEPOSIT_DATE, SET_CE_ADDITIONAL_INFO } from "./actionTypes";
var initialState = {
  compliance: null,
  sendingCurrencyAmount: null,
  receivingCurrencyAmount: null,
  sendingCurrency: {
    country_id: 40,
    currency_code: 'CAD',
    iso2: 'CA'
  },
  receivingCurrency: {
    country_id: 236,
    currency_code: 'USD',
    iso2: 'US'
  },
  rateDetails: null,
  selectedPurpose: null,
  cusEftList: [],
  walletBalance: null,
  transferAmount: null,
  paymentMethod: null,
  paymentEFTInfo: null,
  paymentConnectionType: null,
  paymentOnlineBanks: [],
  paymentFlinksInfo: null,
  depositEFTInfo: null,
  depositConnectionType: null,
  depositMethod: null,
  depositCusEftList: [],
  depositFlinksInfo: null,
  is_new_connection: false,
  deposit_date: null,
  additional_info: null
};
var currencyExchangeReducers = function currencyExchangeReducers() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_CE_RECEIVING_AMOUNT:
      return _objectSpread(_objectSpread({}, state), {}, {
        receivingCurrencyAmount: action.payload
      });
    case SET_CE_RECEIVING_CURRENCY_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        receivingCurrency: action.payload
      });
    case SET_CE_RECEIVING_CURRENCY_RATE_DETAILS:
      return _objectSpread(_objectSpread({}, state), {}, {
        receivingRateDetails: action.payload
      });
    case SET_CE_SENDING_AMOUNT:
      return _objectSpread(_objectSpread({}, state), {}, {
        sendingCurrencyAmount: action.payload
      });
    case SET_CE_SENDING_CURRENCY_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        sendingCurrency: action.payload
      });
    case SET_CE_SENDING_CURRENCY_RATE_DETAILS:
      return _objectSpread(_objectSpread({}, state), {}, {
        sendingRateDetails: action.payload
      });
    case SET_CE_COMPLIANCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        compliance: action.payload
      });
    case SET_CE_TRANSFER_PURPOSE:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedPurpose: action.payload
      });
    case SET_CE_CUS_EFT_LIST:
      return _objectSpread(_objectSpread({}, state), {}, {
        cusEftList: action.payload
      });
    case SET_CE_WALLET_BALANCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        walletBalance: action.payload
      });
    case SET_CE_TRANSFER_AMOUNT:
      return _objectSpread(_objectSpread({}, state), {}, {
        transferAmount: action.payload
      });
    case SET_CE_PAYMENT_METHOD:
      return _objectSpread(_objectSpread({}, state), {}, {
        paymentMethod: action.payload
      });
    case SET_CE_PAYMENT_EFT_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        paymentEFTInfo: action.payload
      });
    case SET_CE_PAYMENT_CONNECTION_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, {
        paymentConnectionType: action.payload
      });
    case SET_CE_PAYMENT_BANKS:
      return _objectSpread(_objectSpread({}, state), {}, {
        paymentOnlineBanks: action.payload
      });
    case SET_CE_PAYMENT_FLINKS_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        paymentFlinksInfo: action.payload
      });
    case SET_CE_DEPOSIT_EFT_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        depositEFTInfo: action.payload
      });
    case SET_CE_DEPOSIT_CONNECTION_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, {
        depositConnectionType: action.payload
      });
    case SET_CE_DEPOSIT_METHOD:
      return _objectSpread(_objectSpread({}, state), {}, {
        depositMethod: action.payload
      });
    case SET_CE_DEPOSIT_CUS_EFT_LIST:
      return _objectSpread(_objectSpread({}, state), {}, {
        depositCusEftList: action.payload
      });
    case SET_CE_DEPOSIT_FLINKS_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        depositFlinksInfo: action.payload
      });
    case SET_CE_RATE_DETAILS:
      return _objectSpread(_objectSpread({}, state), {}, {
        rateDetails: action.payload
      });
    case SET_CE_NEW_MANUAL_CONNECTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        is_new_connection: action.payload
      });
    case SET_CE_DEPOSIT_DATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        deposit_date: action.payload
      });
    case SET_CE_ADDITIONAL_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        additional_info: action.payload
      });
    case CLEAR_CE_DEPOSIT_FLINKS_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        depositFlinksInfo: null
      });
    case CLEAR_CE_PAYMENT_FLINKS_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        paymentFlinksInfo: null
      });
    case CLEAR_CE_STATE:
      return initialState;
    default:
      return state;
  }
};
export default currencyExchangeReducers;