import _defineProperty from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SET_ORIGINAL_COUNTRY_ID, SET_CONVERTED_COUNTRY_ID, SET_EXCHANGE_AMOUNT, SET_CONVERTED_AMOUNT, SET_EXCHANGE_RATE, SET_PROMO_RATE, SET_SPECIAL_RATE, SET_ORIGINAL_CURRENCY_EFT_INFO_ID, SET_CONVERTED_CURRENCY_EFT_INFO_ID, SET_ORIGINAL_CURRENCY_FLINKS_LOGIN_ID, SET_CONVERTED_CURRENCY_FLINKS_LOGIN_ID, SET_ORIGINAL_CURRENCY_FLINKS_LOGS_ID, SET_CONVERTED_CURRENCY_FLINKS_LOGS_ID, SET_ORIGINAL_CURRENCY_EFT_INFO, SET_CONVERTED_CURRENCY_EFT_INFO, CLEAR_EXCHANGE_STATE, SET_CURRENCY_EXCHANGE_PROCESSING_INFO, SET_PAYMENT_BANK_INFO, SET_DEPOSIT_BANK_INFO, SET_EXCHANGE_TRANSFER_PURPOSE, SET_EXCHANGE_FLINKS_DEPOSIT_REQUEST, SET_EXCHANGE_FLINKS_PAYMENT_REQUEST, SET_CURRENCY_EXCHANGE_MANUAL_PAYMENT_FLOW, SET_DEPOSIT_DATE } from './actionTypes';
var initialState = {
  originalCountryId: 40,
  convertedCountryId: 236,
  exchangeAmount: 0,
  convertedAmount: null,
  exchangeRate: null,
  promoRate: null,
  specialRate: null,
  specialRateTransferAmountLimit: null,
  originalCurrencyEftInfoId: null,
  convertedCurrencyEftInfoId: null,
  originalCurrencyFlinksLoginId: null,
  convertedCurrencyFlinksLoginId: null,
  originalCurrencyFlinksLogsId: null,
  convertedCurrencyFlinksLogsId: null,
  originalCurrencyEftInfo: null,
  convertedCurrencyEftInfo: null,
  paymentBankInfo: {},
  depositBankInfo: {},
  currencyExchangeProcessingInfo: {},
  totalFlinksDepositRequests: 0,
  totalFlinksPaymentRequests: 0,
  transferPurpose: '',
  includeManualEftPaymentFlow: false,
  deposit_date: null
};
var reducer = function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_ORIGINAL_COUNTRY_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        originalCountryId: action.payload
      });
    case SET_CONVERTED_COUNTRY_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        convertedCountryId: action.payload
      });
    case SET_EXCHANGE_AMOUNT:
      return _objectSpread(_objectSpread({}, state), {}, {
        exchangeAmount: action.payload
      });
    case SET_CONVERTED_AMOUNT:
      return _objectSpread(_objectSpread({}, state), {}, {
        convertedAmount: action.payload
      });
    case SET_EXCHANGE_RATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        exchangeRate: action.payload
      });
    case SET_PROMO_RATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        promoRate: action.payload
      });
    case SET_SPECIAL_RATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        specialRate: action.payload.specialRate,
        specialRateTransferAmountLimit: action.payload.specialRateTransferAmountLimit
      });
    case SET_ORIGINAL_CURRENCY_EFT_INFO_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        originalCurrencyEftInfoId: action.payload
      });
    case SET_CONVERTED_CURRENCY_EFT_INFO_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        convertedCurrencyEftInfoId: action.payload
      });
    case SET_ORIGINAL_CURRENCY_FLINKS_LOGIN_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        originalCurrencyFlinksLoginId: action.payload
      });
    case SET_CONVERTED_CURRENCY_FLINKS_LOGIN_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        convertedCurrencyFlinksLoginId: action.payload
      });
    case SET_ORIGINAL_CURRENCY_FLINKS_LOGS_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        originalCurrencyFlinksLogsId: action.payload
      });
    case SET_CONVERTED_CURRENCY_FLINKS_LOGS_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        convertedCurrencyFlinksLogsId: action.payload
      });
    case SET_ORIGINAL_CURRENCY_EFT_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        originalCurrencyEftInfo: action.payload
      });
    case SET_CONVERTED_CURRENCY_EFT_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        convertedCurrencyEftInfo: action.payload
      });
    case CLEAR_EXCHANGE_STATE:
      return _objectSpread(_objectSpread({}, state), initialState);
    case SET_CURRENCY_EXCHANGE_PROCESSING_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        currencyExchangeProcessingInfo: action.payload
      });
    case SET_PAYMENT_BANK_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        paymentBankInfo: action.payload
      });
    case SET_DEPOSIT_BANK_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        depositBankInfo: action.payload
      });
    case SET_EXCHANGE_TRANSFER_PURPOSE:
      return _objectSpread(_objectSpread({}, state), {}, {
        transferPurpose: action.payload
      });
    case SET_EXCHANGE_FLINKS_DEPOSIT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        totalFlinksDepositRequests: state ? state.totalFlinksDepositRequests + 1 : 1
      });
    case SET_EXCHANGE_FLINKS_PAYMENT_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        totalFlinksPaymentRequests: state ? state.totalFlinksPaymentRequests + 1 : 1
      });
    case SET_CURRENCY_EXCHANGE_MANUAL_PAYMENT_FLOW:
      return _objectSpread(_objectSpread({}, state), {}, {
        includeManualEftPaymentFlow: action.payload
      });
    case SET_DEPOSIT_DATE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          deposit_date: action.payload
        });
      }
    default:
      return state;
  }
};
export default reducer;