import _defineProperty from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SET_ESCALATION_ISSUE_MSG, SET_ESCALATION_ISSUE_TYPES, SET_ESCALATION_SUB_ISSUE_TYPE, SET_ISSUED_TRANSACTION, SET_ISSUE_ATTACHMENT, CLEAR_ESCALATION_STATE, SET_ESCALATION_RESPONSE, SET_ESCALATION_CLOSED } from './actionTypes';
var initState = {
  issueTypeSelected: null,
  issueSubTypeSelected: null,
  transaction: null,
  attachment: null,
  message: null,
  escalationResponse: null,
  escalationClosed: null
};
var reducer = function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_ESCALATION_ISSUE_TYPES:
      return _objectSpread(_objectSpread({}, state), {}, {
        issueTypeSelected: action.payload
      });
    case SET_ESCALATION_SUB_ISSUE_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, {
        issueSubTypeSelected: action.payload
      });
    case SET_ISSUED_TRANSACTION:
      return _objectSpread(_objectSpread({}, state), {}, {
        transaction: action.payload
      });
    case SET_ISSUE_ATTACHMENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        attachment: action.payload
      });
    case SET_ESCALATION_ISSUE_MSG:
      return _objectSpread(_objectSpread({}, state), {}, {
        message: action.payload
      });
    case SET_ESCALATION_RESPONSE:
      return _objectSpread(_objectSpread({}, state), {}, {
        escalationResponse: action.payload
      });
    case SET_ESCALATION_CLOSED:
      return _objectSpread(_objectSpread({}, state), {}, {
        escalationClosed: action.payload
      });
    case CLEAR_ESCALATION_STATE:
      return initState;
    default:
      return state;
  }
};
export default reducer;