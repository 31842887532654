import _defineProperty from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import client from "./client";
import { store } from '../../../../redux/store';
import * as Sentry from '@sentry/nextjs';
import { BrowserLogger } from '../../../browser-logger-v2';
export var apiMainQuery = function apiMainQuery(query, variables) {
  var fetchPolicy = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'network-only';
  return new Promise(function (resolve) {
    var objToBeReturned = {
      success: true,
      errorMessage: null,
      data: null
    };
    client.query({
      variables: variables,
      query: query,
      fetchPolicy: fetchPolicy
    }).then(function (_ref) {
      var data = _ref.data;
      objToBeReturned.data = data;
      resolve(objToBeReturned);
    })["catch"](function (err) {
      var _query$definitions, _query$definitions$, _query$definitions$2, _query$definitions$3;
      var _ref2 = store && store.getState() || {},
        customer = _ref2.customer;
      if (!err.message.includes('498') && (_query$definitions = query.definitions) !== null && _query$definitions !== void 0 && _query$definitions.length && ((_query$definitions$ = query.definitions[0]) === null || _query$definitions$ === void 0 || (_query$definitions$ = _query$definitions$.name) === null || _query$definitions$ === void 0 ? void 0 : _query$definitions$.value) !== 'hasUnreadNotificationsV2Query' && ((_query$definitions$2 = query.definitions[0]) === null || _query$definitions$2 === void 0 || (_query$definitions$2 = _query$definitions$2.name) === null || _query$definitions$2 === void 0 ? void 0 : _query$definitions$2.value) !== 'MoengageCheck' && ((_query$definitions$3 = query.definitions[0]) === null || _query$definitions$3 === void 0 || (_query$definitions$3 = _query$definitions$3.name) === null || _query$definitions$3 === void 0 ? void 0 : _query$definitions$3.value) !== 'isSessionAliveQuery') {
        var _query$definitions2;
        BrowserLogger.error(err, {
          action_from: 'apiMainQuery',
          payload: variables,
          cus_unique_id: customer === null || customer === void 0 ? void 0 : customer.cus_unique_id,
          query: query === null || query === void 0 || (_query$definitions2 = query.definitions) === null || _query$definitions2 === void 0 || (_query$definitions2 = _query$definitions2[0]) === null || _query$definitions2 === void 0 || (_query$definitions2 = _query$definitions2['name']) === null || _query$definitions2 === void 0 ? void 0 : _query$definitions2.value
        });
        Sentry.captureException(err, function (scope) {
          var _query$definitions3, _query$definitions$0$;
          scope.clear();
          scope.setContext("additionalData", {
            cus_unique_id: customer === null || customer === void 0 ? void 0 : customer.cus_unique_id,
            query: query && (query === null || query === void 0 ? void 0 : query.definitions) && (query === null || query === void 0 || (_query$definitions3 = query.definitions) === null || _query$definitions3 === void 0 ? void 0 : _query$definitions3.length) > 0 && (query === null || query === void 0 ? void 0 : query.definitions[0]['name']) && (query === null || query === void 0 || (_query$definitions$0$ = query.definitions[0]['name']) === null || _query$definitions$0$ === void 0 ? void 0 : _query$definitions$0$.value)
          });
          return scope;
        });
      }
      objToBeReturned = _objectSpread(_objectSpread({}, objToBeReturned), {}, {
        success: false,
        errorMessage: err.message
      });
      resolve(objToBeReturned);
    });
  });
};