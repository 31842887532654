var moengage_events = {
  // signup
  SIGNUP_ACCOUNT_TYPE_SELECTED: 'signup_account_type_selected',
  SIGNUP_PREFERRED_SERVICE_SELECTED: 'signup_preferred_service_selected',
  SIGNUP_INT_BILL_TYPE_SELECTED: 'signup_int_bill_type_selected',
  OPEN_BUSINESS_ACCOUNT: 'open_business_account',
  RECIPIENT_COUNTRY_SELECTED: 'receipient_country_selected',
  SEND_MONEY_AMOUNT_CONFIRMED: 'send_money_amount_confirmed',
  RECIPIENT_RECEIVING_METHOD_CONFIRMED: 'receipient_receiving_method_confirmed',
  RECIPIENT_DETAILS_CONFIRMED: 'recipient_details_confirmed',
  PERSONA_INITIATED: 'persona_initiated',
  PERSONA_COMPLETED: 'persona_completed',
  SEND_MONEY_PURPOSE_CONFIRMED: 'send_money_purpose_confirmed',
  SEND_MONEY_PAYMENT_DETAILS_CONFIRMED: 'send_money_payment_details_confirmed',
  FLINKS_IFRAME_AUTHENTICATION_STARTED: 'flinks_iframe_authentication_started',
  FLINKS_IFRAME_AUTHENTICATION_ENDED: 'flinks_iframe_authentication_ended',
  REWARDS_APPLIED: 'rewards_applied',
  CURRENCY_EXCHANGE_AMOUNT_CONFIRMED: 'currency_exchange_amount_confirmed',
  CURRENCY_EXCHANGE_PURPOSE_CONFIRMED: 'currency_exchange_purpose_confirmed',
  CURRENCY_EXCHANGE_SELECT_ACCOUNT_CONNECTION_TYPE: 'currency_exchange_select_account_connection_type',
  CURRENCY_EXCHANGE_PAYMENT_BANK_ACCOUNT_SELECTED: 'currency_exchange_payment_bank_account_selected',
  CURRENCY_EXCHANGE_DEPOSIT_BANK_ACCOUNT_SELECTED: 'currency_exchange_deposit_bank_account_selected',
  LEVEL2_DOCUMENT_SUBMITTED: 'level2_document_submitted',
  GIFT_CARD_COUNTRY_SELECTED: 'gift_card_country_selected',
  GIFT_CARD_PROVIDER_SELECTED: 'gift_card_provider_selected',
  GIFT_CARD_OVERVIEW_CONFIRMED: 'gift_card_overview_confirmed',
  MOBILE_TOP_UP_COUNTRY_SELECTED: 'mobile_top_up_country_selected',
  MOBILE_TOP_UP_OVERVIEW_CONFIRMED: 'mobile_top_up_overview_confirmed',
  BILL_PAYMENT_COUNTRY_SELECTED: 'bill_payment_country_selected',
  BILL_PAYMENT_BILL_TYPE_SELECTED: 'bill_payment_bill_type_selected',
  BILL_PAYMENT_OVERVIEW_CONFIRMED: 'bill_payment_overview_confirmed',
  BA_VERIFICATION_STARTED: 'ba_verification_started',
  BA_VERIFICATION_ADDITIONAL_OWNERS_ADDED: 'ba_verification_additional_owners_added',
  BA_VERIFICATION_ADDITIONAL_DIRECTORS_ADDED: "ba_verification_additional_directors_added",
  ESIM_DESTINATION_SELECTED: 'esim_destination_selected',
  ESIM_PAYMENT_OVERVIEW_CONFIRMED: 'esim_payment_overview_confirmed',
  LEVEL3_VERIFICATION_VIDEO_SUBMITTED: 'level3_verification_video_submitted'
};
export default moengage_events;