export var SET_COUNTRY_ID = 'SET_COUNTRY_ID';
export var SET_COUNTRY_CODE = 'SET_COUNTRY_CODE';
export var SET_COUNTRY_CALLING_CODE = 'SET_COUNTRY_CALLING_CODE';
export var SET_RECIPIENT_PHONE_NUMBER = 'SET_RECIPIENT_PHONE_NUMBER';
export var SET_SELECTED_OPERATOR = 'SET_SELECTED_OPERATOR';
export var SET_AVAILABLE_OPERATORS = 'SET_AVAILABLE_OPERATORS';
export var SET_PRODUCTS = 'SET_PRODUCTS';
export var SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';
export var SET_PRODUCT_AMOUNT = 'SET_PRODUCT_AMOUNT';
export var SET_REQUIRED_FIELDS = 'SET_REQUIRED_FIELDS';
export var SET_REQ_FIELD_INFOS = 'SET_REQ_FIELD_INFOS';
export var SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export var SET_NOT_IN_RANGE_MSG = 'SET_NOT_IN_RANGE_MSG';
export var SET_CONVERSION_LOADING = 'SET_CONVERSION_LOADING';
export var CLEAR_MOBILE_RC_STATE_DT = 'CLEAR_MOBILE_RC_STATE_DT';
export var SET_DEFAULT_COUNTRY_MOB = 'SET_DEFAULT_COUNTRY_MOB';