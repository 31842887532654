export var SET_BUSINESS_ACC = 'SET_BUSINESS_ACC';
export var SET_PERSONAL_ACC = 'SET_PERSONAL_ACC';
export var SET_BUSINESS_COMPLIANCE = 'SET_BUSINESS_COMPLIANCE';
export var TOGGLE_BUSINESS_VERIFICATION = 'TOGGLE_BUSINESS_VERIFICATION';
export var SET_EDIT_ID = 'SET_EDIT_ID';
export var SET_OWNER_DIRECTOR = 'SET_OWNER_DIRECTOR';
export var SET_BUSINESS_ID = 'SET_BUSINESS_ID';
export var SET_PROMO_STATUS = 'SET_PROMO_STATUS';
export var SET_IS_MSB = 'SET_IS_MSB';
export var SET_FLOW = 'SET_FLOW';
export var CLEAR_STATE = 'CLEAR_STATE';
export var SET_CURRENT_FLOW = 'SET_CURRENT_FLOW';
export var SET_BUSINESS_UNIQUE_ID = 'SET_BUSINESS_UNIQUE_ID';
export var SET_BUSINESS_INFO = 'SET_BUSINESS_INFO';
export var SET_BUSINESS_REDIRECT_ROUTE = 'SET_BUSINESS_REDIRECT_ROUTE';
export var SET_OWNER_DIRECTOR_INFO = 'SET_OWNER_DIRECTOR_INFO';
export var SET_OWNER_DIRECTOR_FORM_DATA = 'SET_OWNER_DIRECTOR_FORM_DATA';
export var SET_OWNER_DIRECTOR_FORM_STATUS = 'SET_OWNER_DIRECTOR_FORM_STATUS';
export var SET_OWNERSHIP_INFO = 'SET_OWNERSHIP_INFO';
export var SET_DIRECTORSHIP_INFO = 'SET_DIRECTORSHIP_INFO';
export var SET_DIRECTOR_LIST_TYPE = 'SET_OWNER_DIRECTOR_LIST_TYPE';
export var SET_OWNER_LIST_TYPE = 'SET_OWNER_LIST_TYPE';
export var SET_OWNER_LIST = 'SET_OWNER_LIST';
export var SET_DIRECTOR_LIST = 'SET_DIRECTOR_LIST';
export var SET_DIRECTOR_FORM_STATUS = 'SET_DIRECTOR_FORM_STATUS';
export var SET_DIRECTOR_FORM_DATA = 'SET_DIRECTOR_FORM_DATA';
export var SET_DIRECTOR_UPLOAD_METHOD = 'SET_DIRECTOR_UPLOAD_METHOD';