export var SET_COUNTRY_GIFT = 'SET_COUNTRY_GIFT';
export var SET_RECIPIENT_EMAIL = 'SET_RECIPIENT_EMAIL';
export var SET_OPERATOR_GIFT = 'SET_OPERATOR_GIFT';
export var SET_PRODUCT_DETAIL_GIFT = 'SET_PRODUCT_DETAIL_GIFT';
export var SET_LIST_OF_PRODUCTS_GIFT = 'SET_LIST_OF_PRODUCTS_GIFT';
export var SET_REQ_FIELDS_GIFT = 'SET_REQ_FIELDS_GIFT';
export var SET_REQ_FIELD_INFO_GIFT = 'SET_REQ_FIELD_INFO_GIFT';
export var SET_CUSTOM_AMNT_GIFT = 'SET_CUSTOM_AMNT_GIFT';
export var SET_CUSTOM_MSG_GIFT = 'SET_CUSTOM_MSG_GIFT';
export var CLEAR_PRODUCTS_INFO_GIFT = 'CLEAR_PRODUCTS_INFO_GIFT,';
export var CLEAR_OPERATOR_INFO_GIFT = 'CLEAR_OPERATOR_INFO_GIFT,';
export var CLEAR_ENTIRE_STATE_GIFT = 'CLEAR_ENTIRE_STATE_GIFT';
export var SET_DEFAULT_COUNTRY = 'SET_DEFAULT_COUNTRY';