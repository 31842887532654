export var FB_PIXEL_ID = process.env.FB_ACCOUNT_ID;
export var pageview = function pageview() {
  if (!(window && window.fbq)) return;
  window.fbq("track", "PageView");
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export var event = function event(name) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  if (!(window && window.fbq)) return;
  window.fbq("trackCustom", name, params, options);
};