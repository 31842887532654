import _defineProperty from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SET_TRANSACTION_DETAILS_DT, SET_PAYMENT_TYPE_DT, SET_CARD_DETAIL_DT, SET_TRANSFER_AMNT_DT, SET_FINAL_TRANSFER_AMNT_DT, SET_REWARDS_AMNT_DT, SET_WALLET_BAL_DT, SET_TRANSACTION_STATUS_DT, SET_ADDITIONAL_RESP_DT, SET_TRANSACTION_PAGE_VISITED, SET_DT_TRANS_FLOW, CLEAR_TRANS_STATE_DT, SET_CHALLENGE_PARAMS, SET_TRANSACTION_UNIQUE_ID_DT, SET_REDIRECT_ROUTE, SET_WALLET_BALANCE, SET_PAYMENT_ERROR } from './actionTypes';
var generateUUID = function generateUUID() {
  var _self;
  return (_self = self) === null || _self === void 0 || (_self = _self.crypto) === null || _self === void 0 ? void 0 : _self.randomUUID();
};
var initialState = {
  transfer_amount: '',
  dt_flow: '',
  payment_type: '',
  wallet_balance: '',
  wallet_balance_applied: '',
  destination_country: '',
  service_type: '',
  sub_service_type: '',
  service_provider: '',
  recipient_details: {},
  product: '',
  product_value: '',
  final_transfer_amount: '',
  card: '',
  rewards_amount: '',
  status: '',
  transaction_page_visited: false,
  additional_response: '',
  transactionUniqueId: '',
  challengeParams: {},
  redirectRoute: '',
  error: false,
  payment_error: '',
  payment_card_error: '',
  idempotencyKey: ''
};
var reducer = function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var newState;
  switch (action.type) {
    case SET_TRANSACTION_DETAILS_DT:
      newState = _objectSpread(_objectSpread({}, state), action.payload);
      break;
    case SET_TRANSACTION_PAGE_VISITED:
      if (state.transaction_page_visited === action.payload) return state;
      newState = _objectSpread(_objectSpread({}, state), {}, {
        transaction_page_visited: action.payload
      });
      break;
    case SET_PAYMENT_TYPE_DT:
      if (state.payment_type === action.payload) return state;
      newState = _objectSpread(_objectSpread({}, state), {}, {
        payment_type: action.payload
      });
      break;
    case SET_CARD_DETAIL_DT:
      if (state.card === action.payload) return state;
      newState = _objectSpread(_objectSpread({}, state), {}, {
        card: action.payload,
        idempotencyKey: generateUUID(newState)
      });
      break;
    case SET_TRANSFER_AMNT_DT:
      if (state.transfer_amount === action.payload) return state;
      newState = _objectSpread(_objectSpread({}, state), {}, {
        transfer_amount: action.payload,
        idempotencyKey: generateUUID(newState)
      });
      break;
    case SET_FINAL_TRANSFER_AMNT_DT:
      if (state.final_transfer_amount === action.payload) return state;
      newState = _objectSpread(_objectSpread({}, state), {}, {
        final_transfer_amount: action.payload,
        idempotencyKey: generateUUID(newState)
      });
      break;
    case SET_REWARDS_AMNT_DT:
      if (state.rewards_amount === action.payload) return state;
      newState = _objectSpread(_objectSpread({}, state), {}, {
        rewards_amount: action.payload
      });
      break;
    case SET_WALLET_BAL_DT:
      if (state.wallet_balance_applied === action.payload) return state;
      newState = _objectSpread(_objectSpread({}, state), {}, {
        wallet_balance_applied: action.payload
      });
      break;
    case SET_TRANSACTION_STATUS_DT:
      if (state.status === action.payload) return state;
      newState = _objectSpread(_objectSpread({}, state), {}, {
        status: action.payload
      });
      break;
    case SET_ADDITIONAL_RESP_DT:
      if (state.additional_response === action.payload) return state;
      newState = _objectSpread(_objectSpread({}, state), {}, {
        additional_response: action.payload
      });
      break;
    case SET_DT_TRANS_FLOW:
      if (state.dt_flow === action.payload) return state;
      newState = _objectSpread(_objectSpread({}, state), {}, {
        dt_flow: action.payload
      });
      break;
    case SET_TRANSACTION_UNIQUE_ID_DT:
      if (state.transactionUniqueId === action.payload) return state;
      newState = _objectSpread(_objectSpread({}, state), {}, {
        transactionUniqueId: action.payload
      });
      break;
    case SET_CHALLENGE_PARAMS:
      if (state.challengeParams === action.payload) return state;
      newState = _objectSpread(_objectSpread({}, state), {}, {
        challengeParams: action.payload
      });
      break;
    case SET_REDIRECT_ROUTE:
      if (state.redirectRoute === action.payload) return state;
      newState = _objectSpread(_objectSpread({}, state), {}, {
        redirectRoute: action.payload
      });
      break;
    case SET_WALLET_BALANCE:
      if (state.wallet_balance === action.payload) return state;
      newState = _objectSpread(_objectSpread({}, state), {}, {
        wallet_balance: action.payload
      });
      break;
    case CLEAR_TRANS_STATE_DT:
      newState = initialState;
      break;
    case SET_PAYMENT_ERROR:
      if (state.error === action.payload) return state;
      newState = _objectSpread(_objectSpread({}, state), {}, {
        error: action.payload
      });
      break;
    default:
      return state;
  }
  return _objectSpread({}, newState);
};
export default reducer;