import _defineProperty from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { CLEAR_ESIM_STATE, SET_SELECTED_DESTINATION_TYPE, SET_PLANS_DETAILS, SET_COUNTRIES_DETAILS, SET_SELECTED_DESTINATION_REGION, SET_SELECTED_PLAN_ESIM, SET_RECEIVING_EMAIL_ESIM, SET_COMPATIBILITY_DEVICES_ESIM } from "./actionTypes";
var initialState = {
  destination_type: null,
  selected_destination_region: null,
  countries_details: null,
  plans_list: null,
  selectedPlan: null,
  receiving_email: null,
  compatability_devices: null
};
var reducer = function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_SELECTED_DESTINATION_TYPE:
      return _objectSpread(_objectSpread({}, state), {}, {
        destination_type: action.payload
      });
    case SET_COUNTRIES_DETAILS:
      return _objectSpread(_objectSpread({}, state), {}, {
        countries_details: action.payload
      });
    case SET_SELECTED_DESTINATION_REGION:
      return _objectSpread(_objectSpread({}, state), {}, {
        selected_destination_region: action.payload
      });
    case SET_PLANS_DETAILS:
      return _objectSpread(_objectSpread({}, state), {}, {
        plans_list: action.payload
      });
    case SET_SELECTED_PLAN_ESIM:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedPlan: action.payload
      });
    case SET_RECEIVING_EMAIL_ESIM:
      return _objectSpread(_objectSpread({}, state), {}, {
        receiving_email: action.payload
      });
    case SET_COMPATIBILITY_DEVICES_ESIM:
      return _objectSpread(_objectSpread({}, state), {}, {
        compatability_devices: action.payload
      });
    case CLEAR_ESIM_STATE:
      return initialState;
    default:
      return state;
  }
};
export default reducer;