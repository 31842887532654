import _defineProperty from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { COMPLIANCE, ADD_NEW_RECIPIENT, ADD_NEW_RECIPIENT_FORM, FIRST_TRANSFER, SET_MT_RECIPIENT, SET_MT_RECEIVING_METHOD, SET_MT_IS_INSTANT_SPEED, SET_MT_SENDING_AMOUNT, SET_MT_RECEIVING_AMOUNT, CLEAR_MT_STATE, SET_MT_RATE_DETAILS, SET_MT_PURPOSE_SELECTED, SET_MT_AVAIL_PAY_METHODS, SET_MT_SELECTED_PAY_METHOD, SET_MT_WALLET_BAL, SET_MT_TRANS_STATE, SET_MT_ONLINE_BANKS, SET_MT_PREFERRED_BANK, SET_MT_CUS_CARDS, SET_MT_SELECTED_CARD, SET_MT_CUS_EFT_LIST, SET_MT_CUS_SELECTED_EFT, SET_MT_FLINKS_DETAIL, SET_MT_CLEAR_FLINKS_STATE, SET_MT_TRANS_UNIQ_ID, SET_MT_CHALLENGE_PARAMS, SET_TABAPAY_RESPONSE, SET_MT_TRANS_INFO, SET_MT_REDIRECT_ROUTE, SET_MT_SPEED_OPTIONS, SET_MT_SPEED_DECIDED, SET_MT_INSTANT_RATE, SET_MT_VERIFICATION_STATE, SET_PERSONAL_INFO_FORM, CLEAR_MT_BEN_STATE, SET_MT_DEBIT_API_ERROR, SET_MT_OPEN_REQUEST_DIALOG, SET_MT_STEPS_AVAIL, SET_FIRST_RECIPIENT, SET_EDIT_CARD_INFO, SET_MT_MANUAL_EFT_LIMIT } from './actionTypes';
var flinksState = {
  loginId: null,
  institution: null,
  url: null,
  flinksIntegrationStatus: null,
  requestId: null,
  bank: null
};
var initialState = {
  compliance: null,
  addNewRecipient: false,
  addNewRecipientForm: {},
  firstTransfer: false,
  recipient: null,
  receivingMethod: null,
  sendingAmountState: null,
  receivingAmountState: null,
  is_instant_speed: false,
  instant_rate: null,
  rateDetails: null,
  available_pay_methods: [],
  selected_pay_method: null,
  avail_wallet_balance: null,
  finalTransferFee: null,
  trans_status: null,
  online_banks: [],
  preferredBank: null,
  cusCards: [],
  selectedCard: null,
  eftList: [],
  selectedEft: null,
  flinks: flinksState,
  transaction_unique_id: null,
  challenge_params_state: {},
  transaction_info: {},
  redirect_route: null,
  speed_options: [],
  selected_speed: null,
  verification_state: [],
  personalInfoForm: null,
  debit_api_error: null,
  request_dialog: false,
  stepsAvailable: [],
  firstRecipient: false,
  editCardInfo: null,
  manual_eft_limit: null
};
var moneyTransferReducers = function moneyTransferReducers() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case COMPLIANCE:
      return _objectSpread(_objectSpread({}, state), {}, {
        compliance: action.payload
      });
    case ADD_NEW_RECIPIENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        addNewRecipient: action.payload
      });
    case ADD_NEW_RECIPIENT_FORM:
      return _objectSpread(_objectSpread({}, state), {}, {
        addNewRecipientForm: action.payload
      });
    case FIRST_TRANSFER:
      return _objectSpread(_objectSpread({}, state), {}, {
        firstTransfer: action.payload
      });
    case SET_MT_RECIPIENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        recipient: action.payload
      });
    case SET_MT_RECEIVING_METHOD:
      return _objectSpread(_objectSpread({}, state), {}, {
        receivingMethod: action.payload
      });
    case SET_MT_SENDING_AMOUNT:
      return _objectSpread(_objectSpread({}, state), {}, {
        sendingAmountState: action.payload
      });
    case SET_MT_RECEIVING_AMOUNT:
      return _objectSpread(_objectSpread({}, state), {}, {
        receivingAmountState: action.payload
      });
    case SET_MT_IS_INSTANT_SPEED:
      return _objectSpread(_objectSpread({}, state), {}, {
        is_instant_speed: action.payload
      });
    case SET_MT_RATE_DETAILS:
      return _objectSpread(_objectSpread({}, state), {}, {
        rateDetails: action.payload
      });
    case SET_MT_PURPOSE_SELECTED:
      return _objectSpread(_objectSpread({}, state), {}, {
        transferPurpose: action.payload
      });
    case SET_MT_AVAIL_PAY_METHODS:
      return _objectSpread(_objectSpread({}, state), {}, {
        available_pay_methods: action.payload
      });
    case SET_MT_SELECTED_PAY_METHOD:
      return _objectSpread(_objectSpread({}, state), {}, {
        selected_pay_method: action.payload
      });
    case SET_MT_WALLET_BAL:
      return _objectSpread(_objectSpread({}, state), {}, {
        avail_wallet_balance: action.payload
      });
    case SET_MT_TRANS_STATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        trans_status: action.payload
      });
    case SET_MT_ONLINE_BANKS:
      return _objectSpread(_objectSpread({}, state), {}, {
        online_banks: action.payload
      });
    case SET_MT_PREFERRED_BANK:
      return _objectSpread(_objectSpread({}, state), {}, {
        preferredBank: action.payload
      });
    case SET_MT_CUS_CARDS:
      return _objectSpread(_objectSpread({}, state), {}, {
        cusCards: action.payload
      });
    case SET_MT_SELECTED_CARD:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedCard: action.payload
      });
    case SET_MT_CUS_EFT_LIST:
      return _objectSpread(_objectSpread({}, state), {}, {
        eftList: action.payload
      });
    case SET_MT_CUS_SELECTED_EFT:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedEft: action.payload
      });
    case SET_MT_FLINKS_DETAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        flinks: action.payload
      });
    case SET_MT_TRANS_UNIQ_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        transaction_unique_id: action.payload
      });
    case SET_MT_CLEAR_FLINKS_STATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        flinks: flinksState
      });
    case SET_MT_CHALLENGE_PARAMS:
      return _objectSpread(_objectSpread({}, state), {}, {
        challenge_params_state: action.payload
      });
    case SET_TABAPAY_RESPONSE:
      return _objectSpread(_objectSpread({}, state), {}, {
        tabapay_response: action.payload
      });
    case SET_MT_TRANS_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        transaction_info: action.payload
      });
    case SET_MT_REDIRECT_ROUTE:
      return _objectSpread(_objectSpread({}, state), {}, {
        redirect_route: action.payload
      });
    case SET_MT_SPEED_OPTIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        speed_options: action.payload
      });
    case SET_MT_SPEED_DECIDED:
      return _objectSpread(_objectSpread({}, state), {}, {
        selected_speed: action.payload
      });
    case SET_MT_VERIFICATION_STATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        verification_state: action.payload
      });
    case SET_MT_INSTANT_RATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        instant_rate: action.payload
      });
    case SET_PERSONAL_INFO_FORM:
      return _objectSpread(_objectSpread({}, state), {}, {
        personalInfoForm: action.payload
      });
    case SET_MT_STEPS_AVAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        stepsAvailable: action.payload
      });
    case CLEAR_MT_STATE:
      return initialState;
    case CLEAR_MT_BEN_STATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        recipient: null,
        receivingMethod: null
      });
    case SET_MT_DEBIT_API_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        debit_api_error: action.payload
      });
    case SET_MT_OPEN_REQUEST_DIALOG:
      return _objectSpread(_objectSpread({}, state), {}, {
        request_dialog: action.payload
      });
    case SET_FIRST_RECIPIENT:
      return _objectSpread(_objectSpread({}, state), {}, {
        firstRecipient: action.payload
      });
    case SET_EDIT_CARD_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        editCardInfo: action.payload
      });
    case SET_MT_MANUAL_EFT_LIMIT:
      return _objectSpread(_objectSpread({}, state), {}, {
        manual_eft_limit: action.payload
      });
    default:
      return state;
  }
};
export default moneyTransferReducers;