export var SET_TRANSACTION_DETAILS_DT = 'SET_TRANSACTION_DETAILS_DT';
export var SET_PAYMENT_TYPE_DT = 'SET_PAYMENT_TYPE_DT';
export var SET_CARD_DETAIL_DT = 'SET_CARD_DETAIL_DT';
export var SET_TRANSFER_AMNT_DT = 'SET_TRANSFER_AMNT_DT';
export var SET_FINAL_TRANSFER_AMNT_DT = 'SET_FINAL_TRANSFER_AMNT_DT';
export var SET_REWARDS_AMNT_DT = 'SET_REWARDS_AMNT_DT';
export var SET_WALLET_BAL_DT = 'SET_WALLET_BAL_DT';
export var SET_TRANSACTION_STATUS_DT = 'SET_TRANSACTION_STATUS_DT';
export var SET_ADDITIONAL_RESP_DT = 'SET_ADDITIONAL_RESP_DT';
export var SET_TRANSACTION_PAGE_VISITED = 'SET_TRANSACTION_PAGE_VISITED';
export var CLEAR_TRANS_STATE_DT = 'CLEAR_TRANS_STATE_DT';
export var SET_DT_TRANS_FLOW = 'SET_DT_TRANS_FLOW';
export var SET_CHALLENGE_PARAMS = 'SET_CHALLENGE_PARAMS';
export var SET_TRANSACTION_UNIQUE_ID_DT = 'SET_TRANSACTION_UNIQUE_ID_DT';
export var SET_REDIRECT_ROUTE = 'SET_REDIRECT_ROUTE';
export var SET_WALLET_BALANCE = 'SET_WALLET_BALANCE';
export var SET_PAYMENT_ERROR = 'SET_PAYMENT_ERROR';