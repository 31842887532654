import _defineProperty from "/home/node/node_modules/.pnpm/next@14.2.16_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1_sass@1.65.1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { SET_COUNTRY_ID_INT, SET_SERVICE_ID_INT, SET_SUB_SERVICE_ID_INIT, SET_PROVIDER_ID, SET_LIST_OF_PRODUCTS_INT, SET_REQ_FIELDS_INT, SET_SELECTED_PLAN_INT, SET_REQ_FIELD_INFO_INT, SET_CUSTOM_AMNT_INT, CLEAR_SUB_SERVICE_INT, CLEAR_PRODUCTS_INFO_INT, CLEAR_OPERATOR_INFO_INT, CLEAR_ENTIRE_STATE_INT, SET_DEFAULT_COUNTRY_INT } from './actionTypes';
var initialState = {
  countryId: null,
  serviceId: 3,
  subServiceId: null,
  subServiceName: '',
  operator: null,
  products: null,
  requiredFields: [],
  selectedPlan: null,
  reqFieldInfo: null,
  customAmount: '',
  defaultCountry: null
};
var reducer = function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SET_COUNTRY_ID_INT:
      return _objectSpread(_objectSpread({}, state), {}, {
        countryId: action.payload
      });
    case SET_SERVICE_ID_INT:
      return _objectSpread(_objectSpread({}, state), {}, {
        serviceId: action.payload
      });
    case SET_SUB_SERVICE_ID_INIT:
      return _objectSpread(_objectSpread({}, state), {}, {
        subServiceId: action.payload.id,
        subServiceName: action.payload.label
      });
    case SET_PROVIDER_ID:
      return _objectSpread(_objectSpread({}, state), {}, {
        operator: action.payload
      });
    case SET_LIST_OF_PRODUCTS_INT:
      return _objectSpread(_objectSpread({}, state), {}, {
        products: action.payload
      });
    case SET_REQ_FIELDS_INT:
      return _objectSpread(_objectSpread({}, state), {}, {
        requiredFields: action.payload
      });
    case SET_SELECTED_PLAN_INT:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedPlan: action.payload
      });
    case SET_REQ_FIELD_INFO_INT:
      return _objectSpread(_objectSpread({}, state), {}, {
        reqFieldInfo: action.payload
      });
    case SET_CUSTOM_AMNT_INT:
      return _objectSpread(_objectSpread({}, state), {}, {
        customAmount: action.payload
      });
    case SET_DEFAULT_COUNTRY_INT:
      return _objectSpread(_objectSpread({}, state), {}, {
        defaultCountry: action.payload
      });
    case CLEAR_SUB_SERVICE_INT:
      return _objectSpread(_objectSpread({}, state), {}, {
        subServiceId: null
      });
    case CLEAR_OPERATOR_INFO_INT:
      return _objectSpread(_objectSpread({}, state), {}, {
        operator: null
      });
    case CLEAR_PRODUCTS_INFO_INT:
      return _objectSpread(_objectSpread({}, state), {}, {
        products: null,
        requiredFields: [],
        selectedPlan: null,
        reqFieldInfo: null,
        customAmount: null
      });
    case CLEAR_ENTIRE_STATE_INT:
      return _objectSpread({}, initialState);
    default:
      return state;
  }
};
export default reducer;