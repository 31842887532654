import {
    ADD_CARD,
    UPDATE_ATTEMPTS_BY_CARD_ID,
    REMOVE_CARD,
    UPDATE_ATTEMPTS_BY_DTONE_CARD_ID ,
    REMOVE_DTONE_CARD
} from './actionTypes';

const initialState = {
    cards: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_CARD:
            const newCard = { ...action.payload, attempts: 0 };
            const isCardExists = state.cards.some(card => card.card_last_four_digits === action.payload.card_last_four_digits);
            if (!isCardExists) {
                return {
                    ...state,
                    cards: [...state.cards, newCard]
                };
            }
            return state;
        case UPDATE_ATTEMPTS_BY_CARD_ID:
            const updatedCardsAttempts = state.cards.map(card =>
                card.card_last_four_digits === action.payload
                    ? { ...card, attempts: card.attempts + 1 }
                    : card
            );
            return {
                ...state,
                cards: updatedCardsAttempts
            };
            case UPDATE_ATTEMPTS_BY_DTONE_CARD_ID:
            const updatedDtoneCardsAttempts = state.cards.map(card =>
                card.cardInfo === action.payload
                    ? { ...card, attempts: card.attempts + 1 }
                    : card
            );
            return {
                ...state,
                cards: updatedDtoneCardsAttempts
            };
            case REMOVE_DTONE_CARD:
                const updatedDtoneCardsAfterRemove = state.cards.filter(card =>
                    card.cardInfo !== action.payload
                );
                return {
                    ...state,
                    cards: updatedDtoneCardsAfterRemove
                };
        case REMOVE_CARD:
            const updatedCardsAfterRemove = state.cards.filter(card =>
                card.card_last_four_digits !== action.payload
            );
            return {
                ...state,
                cards: updatedCardsAfterRemove
            };
        default:
            return state;
    }
};

export default reducer;
